import styled from 'styled-components'


export const CssNoAlertsText = styled.div`
    background-color: ${props => props.theme.colors.bg.box.info};
    height          : 100%;
    border-radius   : ${props => props.theme.radius.medium};
    flex            : 1;
    position        : absolute;
    width           : 100%;
    p{
        color     : ${props => props.theme.colors.text.default};
        font-style: normal;
        font-weight: 400;
        position  : absolute;
        margin    : 0;
        text-align: center;
        width     : 100%;
        top       : 50%;
        transform : translateY(-50%);
    }
`

export const CssWrapper = styled.div`
    height    : ${props => props.theme.size.layout_6};
    display   : flex;
    flex-direction: row;
    width: 100%;
`