import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';
import { MediaSvg } from '@apd/media-svg';
import { Close } from '@material-ui/icons';
import { Button } from '@material-ui/core';
import { criteria } from '../../../components/ipmaHelper';
import {
  CssBox,
  CssTitle,
  CssContent,
  CssCol,
  CssArea,
  CssData,
  CssValue,
  CssTag,
  CssXS,
  CssRow,
  CssValueXS,
  CssIcon,
  CssButton
} from './FullStationBox-style';

import { useWeatherStationHistoricalData } from '../../../hooks/useWeatherStationHistoricalData';

export const FullStationBox = ({
  id,
  name,
  temperature,
  windSpeed,
  precipitation,
  uVIndexMax,
  onModalClose,
  hasCloseButton = false
}) => {
  const themeContext = useContext(ThemeContext);
  const {
    setReqHist,
    setEntityId,
    entityId,
    _maxTemperature,
    _minTemperature,
    _maxWind,
    _lastHourPrecipitation,
    _lastSixPrecipitation
  } = useWeatherStationHistoricalData();

  useEffect(() => {
    if (entityId === null) {
      setEntityId(id);
      return null;
    }

    setReqHist(true);
  }, [entityId, id, setEntityId, setReqHist]);

  return (
    <CssBox>
      <CssTitle>{decodeURI(name)}</CssTitle>
      <CssContent>
        {/* TEMPERATURE START --- AREA FOR THE TEMPERATURE INFORMATION */}
        {TemperatureUI({
          temperature,
          _maxTemperature,
          _minTemperature,
          theme: themeContext.colors.text.category
        })}
        {/* TEMPERATURE END --- AREA FOR THE TEMPERATURE INFORMATION */}
        {/* WIND START --- AREA FOR THE WIND INFORMATION */}
        {WindSpeedUI({
          windSpeed,
          _maxWind,
          theme: themeContext.colors.text.category
        })}
        {/* WIND END --- AREA FOR THE WIND INFORMATION */}
      </CssContent>
      <CssContent>
        {/* Precipitation START --- AREA FOR THE WIND INFORMATION */}
        {PrecipitationUI({
          precipitation,
          _lastHourPrecipitation,
          _lastSixPrecipitation,
          theme: themeContext.colors.text.category
        })}
        {/* Precipitation END --- AREA FOR THE WIND INFORMATION */}
        {/* uVIndexMax START --- AREA FOR THE WIND INFORMATION */}
        {UVIndexUI({ uVIndexMax })}
        {/* uVIndexMax END --- AREA FOR THE WIND INFORMATION */}
      </CssContent>
      {hasCloseButton ? (
        <CssButton>
          <span>
            <Button onClick={onModalClose} variant='text'>
              <Close htmlColor='#000f9f' />
            </Button>
          </span>
        </CssButton>
      ) : null}
    </CssBox>
  );
};

FullStationBox.propTypes = {
  name: PropTypes.string,
  temperature: PropTypes.number,
  windSpeed: PropTypes.number,
  precipitation: PropTypes.number,
  uVIndexMax: PropTypes.number
};

FullStationBox.defaultProps = {
  name: '- -',
  temperature: 0,
  windSpeed: 0,
  precipitation: 0,
  uVIndexMax: 0
};

// -------------- UI components  ---------------

/**
 *
 * @param {temperature, _maxTemperature, _minTemperature, theme}
 * @returns The UI Component for the temperature
 */
const TemperatureUI = ({
  temperature,
  _maxTemperature,
  _minTemperature,
  theme
}) => {
  const [temp, setTemp] = useState(null);
  const [maxTemp, setMaxTemp] = useState(null);
  const [minTemp, setMinTemp] = useState(null);

  useEffect(() => {
    if (!temperature) {
      setTemp(null);
      return;
    }
    setTemp(temperature.toFixed(1));
  }, [temperature]);

  useEffect(() => {
    if (!_maxTemperature) {
      setMaxTemp(null);
      return;
    }
    setMaxTemp(_maxTemperature.toFixed(1));
  }, [_maxTemperature]);

  useEffect(() => {
    if (!_minTemperature) {
      setMinTemp(null);
      return;
    }
    setMinTemp(_minTemperature.toFixed(1));
  }, [_minTemperature]);

  return (
    <CssCol>
      <CssArea>
        <CssIcon>
          <MediaSvg name={`ipma:temp`} fill={theme} />
        </CssIcon>
        Temperatura
      </CssArea>
      <CssData>
        <CssValue wcriteria={temp ? criteria.temp(temp) : null} wtype='temp'>
          {temp ? temp : '-'}
          <CssXS> ºC</CssXS>
        </CssValue>
        <CssTag>Atual</CssTag>
      </CssData>
      <CssRow>
        <CssData>
          <CssValueXS
            wcriteria={maxTemp ? criteria.temp(maxTemp) : null}
            wtype='temp'
          >
            {maxTemp ? maxTemp : '-'}
            <CssXS> ºC</CssXS>
          </CssValueXS>
          <CssTag>Máx.</CssTag>
        </CssData>
        <CssData>
          <CssValueXS wcriteria={minTemp ? criteria.temp(minTemp) : null}>
            {minTemp ? minTemp : '-'}
            <CssXS> ºC</CssXS>
          </CssValueXS>
          <CssTag>Min.</CssTag>
        </CssData>
      </CssRow>
    </CssCol>
  );
};

/**
 *
 * @param {windSpeed, _maxWind, theme}
 * @returns The UI Component for the WindSpeed
 */
const WindSpeedUI = ({ windSpeed, _maxWind, theme }) => (
  <CssCol>
    <CssArea>
      <CssIcon>
        <MediaSvg name={`ipma:wind`} fill={theme} />
      </CssIcon>
      Vento
    </CssArea>
    <CssData>
      <CssValue wcriteria={criteria.wind(Math.round(windSpeed * 36) / 10)}>
        {Math.round(windSpeed * 36) / 10}
        <CssXS> km/h</CssXS>
      </CssValue>
      <CssTag>Vel. atual</CssTag>
    </CssData>
    <CssRow>
      <CssData>
        <CssValueXS
          wcriteria={
            _maxWind ? criteria.wind(Math.round(_maxWind * 36) / 10) : 0
          }
        >
          {_maxWind ? Math.round(_maxWind * 36) / 10 : 0}
          <CssXS> km/h</CssXS>
        </CssValueXS>
        <CssTag>Máx.</CssTag>
      </CssData>
    </CssRow>
  </CssCol>
);

/**
 *
 * @param {windSpeed, _maxWind, theme}
 * @returns The UI Component for the precipitation
 */
const PrecipitationUI = ({
  precipitation,
  _lastHourPrecipitation,
  _lastSixPrecipitation,
  theme
}) => (
  <CssCol>
    <CssArea>
      <CssIcon>
        <MediaSvg name={`ipma:rainfall`} fill={theme} />
      </CssIcon>
      Precipitação
    </CssArea>
    <CssData>
      <CssValue>
        {precipitation}
        <CssXS>mm</CssXS>
      </CssValue>
      <CssTag>Pp. atual</CssTag>
    </CssData>
    <CssRow>
      <CssData>
        <CssValueXS
          wcriteria={
            _lastHourPrecipitation
              ? criteria.rain_1h(_lastHourPrecipitation)
              : null
          }
        >
          {_lastHourPrecipitation ? _lastHourPrecipitation.toFixed(1) : 0}
          <CssXS>mm</CssXS>
        </CssValueXS>
        <CssTag>1 hora</CssTag>
      </CssData>
      <CssData>
        <CssValueXS
          wcriteria={
            _lastSixPrecipitation
              ? criteria.rain_6h(_lastSixPrecipitation)
              : null
          }
        >
          {_lastSixPrecipitation ? _lastSixPrecipitation.toFixed(1) : 0}
          <CssXS>mm</CssXS>
        </CssValueXS>
        <CssTag>6 horas</CssTag>
      </CssData>
    </CssRow>
  </CssCol>
);

const UVIndexUI = ({ uVIndexMax }) => (
  <CssCol>
    <CssArea>Radiação UV</CssArea>
    <CssData>
      <CssValue wcriteria={criteria.uv(uVIndexMax)} wtype='uv'>
        {uVIndexMax ? uVIndexMax : '-'}
        <CssXS>UV</CssXS>
      </CssValue>
      <CssTag>Atual</CssTag>
    </CssData>
  </CssCol>
);
