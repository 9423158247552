import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import c from '../../../../constants/config';
import axios from 'axios';
import moment from 'moment';
import 'moment/min/locales';
import { precipitationSchema } from '../../../../schema';
moment.locale('pt');

export const ChartPrecipitation = ({
  from_date,
  to_date,
  aggr_period,
  station_id
}) => {
  const [precipitationSet, setPrecipitationSet] = useState([]);
  const [precipitationLabels, setPrecipitationLabels] = useState([]);

  useEffect(() => {
    if (!station_id || !from_date || !to_date || !aggr_period) return;
    setPrecipitationLabels([]);
    setPrecipitationSet([]);

    const url = `${c.api.weather_station}${station_id}?from_date=${from_date}&to_date=${to_date}&attribute_name=precipitation&aggr_period=${aggr_period}&aggr_method=sum`;
    console.log(from_date);
    console.log(to_date);
    let thislabel = [];
    let thisset = [];
    axios
      .get(url)
      .then((resp) => {
        precipitationSchema.validateSync(resp.data);
        resp.data.request_entities.map((item, i) => {
          thislabel.push(item.date);
          thisset.push(Number(item.precipitation.toFixed(2)));
          return null;
        });

        setPrecipitationLabels(thislabel);
        setPrecipitationSet(thisset);
      })
      .catch((error) => console.log('error: ', error));
  }, [from_date, to_date, aggr_period, station_id]);

  return (
    <Bar
      data={{
        labels: precipitationLabels,
        datasets: [
          {
            label: 'Precipitação',
            data: precipitationSet,
            backgroundColor: '#4D57BC',
            borderWidth: 1
          }
        ]
      }}
      // width={100}
      // height={330}
      options={{
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          align: 'end'
        },
        scales: {
          xAxes: [
            {
              offset: true,
              type: 'time',
              time: {
                stepSize: 1,
                unit: aggr_period,
                tooltipFormat: 'D MMM YYYY - h a'
              },
              scaleLabel: {
                display: true,
                labelString: `${moment
                  .tz(
                    moment(from_date, 'YYYY-MM-DD[T]H:mm:ss.000'),
                    'Europe/Lisbon'
                  )
                  .format('DD MMM YYYY - HH:mm')} | ${moment
                  .tz(to_date, 'Europe/Lisbon')
                  .format('DD MMM YYYY - HH:mm')}`,
                fontSize: 14,
                fontStyle: 'normal',
                padding: 24
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true
              }
            }
          ]
        }
      }}
    />
  );
};
