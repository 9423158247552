import styled, {keyframes} from 'styled-components'

export const CssBody = styled.div`
    background-color: black;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 1917px;
    height: 1024px;
    margin-left: auto;
    margin-right: auto;
`

export const CssWStationwrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    position: relative;
    width: 1908px;
    height:468px;
    margin-left: ${props => props.theme.size.spacing_3};
    margin-top: ${props => props.theme.size.spacing_2};
    margin-top: ${props => props.theme.size.spacing_3};
`

export const CssMainStation = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 500px;
    height: 420px;
    margin-right: ${props => props.theme.size.spacing_3};
    &>div{
        width: 100%;
        height: 100%;
    }
`

export const CssSmallStation = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 1395px;
    height: 468px;
    overflow:hidden;

`


const CssWrapperSlider = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 2000px;
    overflow: hidden;
    height: 229px;

    &>div{
        margin-right: ${props => props.theme.size.spacing_3};
        width: 411px;
        height: 182px;
   
    }

    &>div:nth-child(4){
        margin-right: 0;
    }
`

const animeRight = keyframes`
    from {margin-left: -467px;}
    to {
        margin-left: 0;
    }
`

export const CssTop = styled(CssWrapperSlider)`
    
    margin-bottom: ${props => props.theme.size.spacing_3}; 

    &>div:nth-child(1){

        animation-name: ${animeRight}; 
        animation-fill-mode: forwards;
        animation-duration: 1s;
    }

`

const animeLeft = keyframes`
    to {
        margin-left: -467px;
    }
`

export const CssBottom = styled(CssWrapperSlider)`

    &>div:nth-child(1){
        animation-name: ${animeLeft}; 
        animation-fill-mode: forwards;
        animation-duration: 1s;
    }
`


export const CssAnimeLeft = styled.div`
    
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    overflow: hidden;
`


/**
 * 
 * WRAPPER -> IPMA Foracast
 * 
 */

export const CssIPMAwrapper= styled.div`
    display: flex;
    flex-wrap: wrap;
    position: relative;
    width: 1904px;
    margin-left: ${props => props.theme.size.spacing_3};
    margin-right: ${props => props.theme.size.spacing_3};
    margin-bottom: ${props => props.theme.size.spacing_3};
    margin-top: ${props => props.theme.size.spacing_3};
    height: 436px;
`

export const CssWarningWrapper= styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 968px;
    height: 436px;
    margin-right: ${props => props.theme.size.spacing_3};

    &>div{
        width: 100%;
    }
`
export const CssForcastWrapper= styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 925px;
    height: 436px;
    &>div:first-of-type{
        margin-bottom: ${props => props.theme.size.spacing_3};
    }
    &>div{
        width: 100%;
    }

`


/**
 * 
 * WRAPPER -> Message
 * 
 */

export const CssWarningMsgWrapper= styled.div`
    width: 1900px;
    height: 90px;
    margin-left: ${props => props.theme.size.spacing_3};
    margin-right: ${props => props.theme.size.spacing_3};
    display: flex;
    position: relative;
    &>div{
        margin-top: 0px;
    }
`


export const CssScale = styled.div` 
    width: 645px;
    transform: translate(20%,29%) scale(1.42);
`
