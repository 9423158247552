import React, { useState, useEffect, useRef } from 'react'
import { CssAlertWrapper, CssBox } from './IpmaWarningCarouselAdvance-style'
import { MessageWarning } from './MessageWarning/MessageWarning'
import { MessageNoWarning } from './MessageNoWarning/MessageNoWarning'

import moment from 'moment'
import 'moment/min/locales'
moment.locale('pt')

/**
 * setup this component
 */

const setup = {
    timer2Show: 30000
}

/**
 * 
 * AlertMessage
 *  
 */
export const IpmaWarning = ({ alerts }) => {
    const [list, setList] = useState([])
    const increment = useRef(null);

    useEffect(() => {
        setList(alerts)
    }, [alerts])

    useEffect(() => {
        if (list.length < 2) return
        increment.current = setInterval(() => {
            // loop elements
            const [first, ...rest] = list
            setList([...rest,first])
            
        }, setup.timer2Show);

        return () => clearInterval(increment.current)

    }, [list]);

    if (list.length === 0) {
        return (
            <CssAlertWrapper>
                <MessageNoWarning />
            </CssAlertWrapper>
        )
    }

    return (
        <CssAlertWrapper>
            {list.map((elem) => (
                <CssBox key={elem.id}>
                    <MessageWarning {...elem} />
                </CssBox>
            )
            )}
        </CssAlertWrapper>
    )
}
