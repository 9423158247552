import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import c from '../../../../constants/config';
import axios from 'axios';
import moment from 'moment';
import 'moment/min/locales';
import { windSpeedSchema } from '../../../../schema';
moment.locale('pt');

export const ChartWind = ({ from_date, to_date, aggr_period, station_id }) => {
  const [dataMaxWind, setDataMaxWind] = useState([]);
  const [dataVelWind, setDataVelWind] = useState([]);
  const [dataLabels, setDataLabels] = useState([]);
  const [dataHighNumber, setDataHighNumber] = useState(0);
  const [dataLowNumber, setDataLowNumber] = useState(0);

  useEffect(() => {
    if (!station_id || !from_date || !to_date || !aggr_period) return;
    setDataMaxWind([]);
    setDataVelWind([]);
    setDataLabels([]);

    const getmax = axios.get(
      `${c.api.weather_station}${station_id}?from_date=${from_date}&to_date=${to_date}&attribute_name=windSpeed&aggr_period=${aggr_period}&aggr_method=max`
    );
    const getsum = axios.get(
      `${c.api.weather_station}${station_id}?from_date=${from_date}&to_date=${to_date}&attribute_name=windSpeed&aggr_period=${aggr_period}&aggr_method=avg`
    );

    axios
      .all([getmax, getsum])
      .then(
        axios.spread((...responses) => {
          let lowNumber = 0;
          let label = [];
          let max = [];
          let min = [];
          windSpeedSchema.validateSync(responses[0].data);
          responses[0].data.request_entities.map((item, index) => {
            label.push(item.date);
            max.push(Math.round((item.windSpeed * 36) / 10));
            windSpeedSchema.validateSync(responses[1].data);
            const value = responses[1].data.request_entities[index].windSpeed;
            min.push(Math.round((value * 36) / 10));
            return null;
          });

          const highNumber = Math.max(...max) + 10;
          setDataMaxWind(max);
          setDataVelWind(min);
          setDataLabels(label);

          setDataHighNumber(highNumber);
          setDataLowNumber(lowNumber);
        })
      )
      .catch((errors) => console.log('Error', errors));
  }, [from_date, to_date, aggr_period, station_id]);

  return (
    <Line
      data={{
        labels: dataLabels,
        datasets: [
          {
            label: 'Velocidade máxima',
            data: dataMaxWind,
            fill: false,
            backgroundColor: '#999FD9',
            borderColor: '#999FD9'
          },
          {
            label: 'Velocidade media',
            data: dataVelWind,
            fill: false,
            backgroundColor: '#4D57BC',
            borderColor: '#4D57BC'
          }
        ]
      }}
      // width={100}
      // height={330}
      options={{
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          mode: 'index',
          intersect: false
        },
        hover: {
          mode: 'nearest',
          intersect: true
        },
        legend: {
          align: 'end'
        },
        scales: {
          xAxes: [
            {
              offset: true,
              type: 'time',
              time: {
                stepSize: 1,
                unit: aggr_period,
                tooltipFormat: 'D MMM YYYY - h a'
              },
              scaleLabel: {
                display: true,
                labelString: `${moment
                  .tz(
                    moment(from_date, 'YYYY-MM-DD[T]H:mm:ss.000'),
                    'Europe/Lisbon'
                  )
                  .format('DD MMM YYYY - HH:mm')} | ${moment
                  .tz(to_date, 'Europe/Lisbon')
                  .format('DD MMM YYYY - HH:mm')}`,
                fontSize: 14,
                fontStyle: 'normal',
                padding: 24
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                max: dataHighNumber,
                min: dataLowNumber
              }
            }
          ]
        }
      }}
    />
  );
};
