import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, ZoomControl } from "react-leaflet";
import { CssMap, CssWrapper } from "./MapScreen-style";

import WStationMakers from "./MapComponents/WStationMarker";
import { SideBar } from "./MapComponents/Sidebar/SideBar";
import c from "../../constants/config";

// import { DropdownDataType } from './MapComponents/dropdownDataType'

import { useSocketWeatherObserved } from "../../hooks/useSocketWeatherObserved";
import { CityLimits } from "./MapComponents/CityLimits";

import Legend from "./MapComponents/Legend";

// var _paq = (window._paq = window._paq || []);
// /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
// _paq.push(["trackPageView"]);
// _paq.push(["enableLinkTracking"]);
// (function () {
//   var u = "https://matomo.porto.digital/";
//   _paq.push(["setTrackerUrl", u + "matomo.php"]);
//   _paq.push(["setSiteId", "15"]);
//   var d = document,
//     g = d.createElement("script"),
//     s = d.getElementsByTagName("script")[0];
//   g.async = true;
//   g.src = u + "matomo.js";
//   s.parentNode.insertBefore(g, s);
// })();

const MapScreen = () => {
  const { wStationList } = useSocketWeatherObserved();

  const [highlightID, setHighlightID] = useState(null);
  const [highlightStation, setHighlightStation] = useState({
    id: "id",
    name: "name",
    temperature: 0,
    solarRadiation: 0,
    windSpeed: 0,
    atmosphericPressure: 0,
    relativeHumidity: 0,
  });
  const [isHighlight, setIsHighlight] = useState(false);
  // const [dataType, setDataType] = useState(null)

  const activeStation = (id) => {
    setHighlightID(id);
  };
  useEffect(() => {
    document.title = "Mapa | PMGM";
  }, []);
  useEffect(() => {
    if (highlightID === null) return;

    setHighlightStation(wStationList[highlightID]);
    setIsHighlight(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highlightID]);

  // const selectDataType = id => {
  // 	if(id === dataType) return null
  // 	console.log(id)
  // 	setDataType(id)
  // }

  const closeSideBar = () => {
    setIsHighlight(false);
  };

  return (
    <CssWrapper>
      <CssMap>
        {/* -------------------------------------
						Map component
					------------------------------------- */}

        <MapContainer
          // onViewportChanged={onViewportChanged}
          center={c.map.center}
          zoom={c.map.zoom}
          zoomControl={false}
          style={{ height: "100%", width: "100%" }}
        >
          <TileLayer
            url={c.map.tile}
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a>'
          />
          <ZoomControl position="bottomright" />
          <CityLimits />

          <Legend />
          {/* <Legend key={dataType} type={dataType}/> */}

          <WStationMakers data={wStationList} event={activeStation} />
        </MapContainer>

        {/* <CssDropWrapper>
						<DropdownDataType event={selectDataType} />
					</CssDropWrapper> */}
      </CssMap>
      <SideBar
        sendShow={isHighlight}
        {...highlightStation}
        event={closeSideBar}
      />
    </CssWrapper>
  );
};

export default MapScreen;
