import { useMap } from "react-leaflet"
import { ThemeContext } from "styled-components"
import L from "leaflet"
import { useEffect, useContext } from "react"

const Legend = () => {
	const themeContext = useContext(ThemeContext)
	const map = useMap()

	/**
	 * style for the legend wrapper
	*/
	const CssContent = `
		 padding: ${themeContext.size.spacing_3};
		 background: rgba(255,255,255,0.8);
		 border-radius: ${themeContext.radius.small};
	 `

	 const CssSpan = `
		 color: ${themeContext.colors.text.default_2};
		 display: block;
		 margin-bottom: ${themeContext.size.spacing_3};
	 `

	useEffect(() => {
		// get color depending on population density value
		const getColor = d => {
			let color = ""

			if ( d > -10 ) color = "#773C93"
			if ( d > -5 ) color  = "#7C61A1"
			if ( d > 0 )  color  = "#818BB9"
			if ( d > 5 )  color  = "#ABD3E3"
			if ( d > 10 ) color  = "#DBF1F8"
			if ( d > 15 ) color  = "#F8DD8D"
			if ( d > 20 ) color  = "#F3A962"
			if ( d > 25 ) color  = "#E46B46"
			if ( d > 30 ) color  = "#BE3A29"
			if ( d > 35 ) color  = "#681E23"
			if ( d > 40 ) color  = "#9A339D"
			
			return color
		}

		const legend = L.control({ position: "bottomleft" });

		legend.onAdd = () => {
			const htmlLegend = L.DomUtil.create("div", "info legend");
			const grades = [-10, -5, 0, 5, 10, 15, 20, 25, 30, 35, 40];
			let colorArray = ""
			let from, to

			for (let i = 0; i < grades.length; i++) {
				from = grades[i];
				to = grades[i + 1];

				colorArray += `
				<div style="
				display: block;
				margin-bottom: 1px;
				">
				<i style="
					background: ${getColor(from + 1)}; 
					width: 18px; 
					height: 18px; 
					float: left;
					margin-right: 8px;
					opacity: 0.8;" >
				</i>
					${from +
					(to ?
						", " + to :
						(to === 0 ? ", 0" : " +"))
					}
				</div>`

			}
	
			htmlLegend.innerHTML = `<div style="${CssContent}"> 
		  		<span style="${CssSpan}"> 
					Temp. do ar [ºC] 
				</span>
					${colorArray}
		  		</div> `

			return htmlLegend
		}

		legend.addTo(map)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	return null
}

export default Legend;
