import React, { useContext, useState, useEffect, useRef } from 'react'
import MaterialTable from 'material-table'
import tableIcons from './SmartTableSetup/setupTableIcons'
import localization_PT from './SmartTableSetup/setupLocalizationPT'
import c from '../../../constants/config'
import { ThemeContext } from 'styled-components'
import { MediaSvg } from  '@apd/media-svg' 

import {CssIconTable, CssIcon, CssTimeViewDate, CssTimeViewHour} from './SmartTable-style'

import moment from 'moment'
import 'moment/min/locales'
import { weatherHistoryAlertSchema } from '../../../schema'
moment.locale('pt')

const format = "YYYY-MM-DD[T]HH:mm:ss.000"


const SmartTable = ({dates}) => {
    const [queryDates, setQueryDates] = useState(null)

    useEffect(() => {
        if(!dates) return null
        setQueryDates(dates)
    }, [dates])

    
    return (queryDates && 
        <Table key={moment(dates.startDate).format(format).toString()}
            from_date={moment(dates.startDate).startOf('day').format(format).toString()} 
            to_date={moment(dates.endDate).endOf('day').format(format).toString()} />
    )
}

/**
 * 
 * Table Elememt
 */
const Table = ({from_date, to_date}) => {
    const [pageSize, setPageSize] = useState(10)
    const tableRef = useRef(null)
    const themeContext = useContext(ThemeContext)

    const handleChangeRowsPerPage = event => setPageSize(event)

    // if (!from_date || !to_date) return null
    return (
        <MaterialTable
            isLoading={false}
            icons={tableIcons}
            tableRef={tableRef}
            title=""
            columns={[
                {
                    title    : '#',
                    align    : "left",
                    width    : null,
                    cellStyle: {
                        padding   : 0,
                        width     : 60,
                        minWidth  : 60,
                        whiteSpace: 'nowrap'
                    },
                    headerStyle: {
                        width     : 60,
                        whiteSpace: 'nowrap',
                        minWidth  : 60
                        },
                    render: row => (
                        <CssIconTable severity={row.severity}>
                            <CssIcon>
                                <MediaSvg 
                                    name={`ipma:${row.subCategory}`} 
                                    fill={themeContext.colors.bg.ipma[row.severity]} 
                                /> 
                            </CssIcon>
                        </CssIconTable>)
                },
                {
                    title: 'Descrição',
                    align: "left",
                    field: 'description',
                },
                {
                    title: 'Data início',
                    align: "left",
                    field: 'validFrom',
                    width    : null,
                    cellStyle: {
                        width     : 160,
                        minWidth  : 160,
                        whiteSpace: 'nowrap'
                    },
                    headerStyle: {
                        width     : 160,
                        whiteSpace: 'nowrap',
                        minWidth  : 160
                        },
                    render: row => (<>
                      <CssTimeViewDate>{moment.tz(row.validFrom, 'Europe/Lisbon').format('DD MMMM YYYY')}</CssTimeViewDate>
                      <CssTimeViewHour>{moment.tz(row.validFrom, 'Europe/Lisbon').format('HH:mm')}</CssTimeViewHour>
                    </>),
                },
                {
                    title: 'Data término',
                    align: "left",
                    field: 'validTo',
                    width    : null,
                    cellStyle: {
                        width     : 160,
                        minWidth  : 160,
                        whiteSpace: 'nowrap'
                    },
                    headerStyle: {
                        width     : 160,
                        whiteSpace: 'nowrap',
                        minWidth  : 160
                        },
                    render: row => (<>
                        <CssTimeViewDate>{moment.tz(row.validTo, 'Europe/Lisbon').format('DD MMMM YYYY')}</CssTimeViewDate>
                        <CssTimeViewHour>{moment.tz(row.validTo, 'Europe/Lisbon').format('HH:mm')}</CssTimeViewHour>
                      </>),
                },
            ]}
            data={query =>
                new Promise((resolve, reject) => {
                    setPageSize(query.pageSize)

                    let url = `${c.api.weatherhistoryAlert}?`
                    url += 'page_size=' + query.pageSize
                    url += '&page=' + (query.page + 1)
                    url += '&from_date=' + (from_date)
                    url += '&to_date=' + (to_date)
                    fetch(url)
                        .then(response => response.json())
                        .then(res =>  weatherHistoryAlertSchema.validate(res))
                        .then(result => {
                            resolve({
                                data      : result.request_entities,
                                page      : result.request_params.page - 1,
                                totalCount: result.request_params.total_records,
                            })
                        })
                        .catch(err => console.error(err))
                })
            }
            options={{
                exportButton   : false,
                sorting        : false,
                paging         : true,
                search         : false,
                exportAllData  : false,
                toolbar        : false,
                pageSizeOptions: [10, 20, 40, 80],
                pageSize       : pageSize,
                initialPage    : 0,
                paginationType : "normal",
                loadingType    : "overlay",
                draggable      : false,
                paginationPosition: "bottom"

            }}
            localization={localization_PT}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            // onChangePage={handleChangePageNumber}
        />
    )
}




export default SmartTable