import styled, {keyframes} from 'styled-components'

/**
 * 
 * Shadowm pulse effect
 */

 const pulseAnime = keyframes`
    0% {
        transform: scale(1);
    }
    95% {
       transform  : translate(5px, 0px) scale(1.3);    
    }
    100% {
        transform  : scale(1);
    }
`

/**
 * Defaut styles
 */


export const CssContent = styled.div`
    display        : flex;
    flex-direction : row;
    justify-content: space-between;
    align-items    : center;
    height         : auto;
    padding        : 0px;
    flex-wrap      : wrap;
`

export const CssArea = styled.div`
    color        :  ${props => props.theme.colors.text.category};
    margin-bottom: ${props => props.theme.size.spacing_5};
    line-height: ${props => props.theme.size.layout_2};
    min-height: 24px;
`

export const CssIcon = styled.div`
    width: ${props => props.theme.size.layout_2};
    height: ${props => props.theme.size.layout_2};
    float:left;
    margin-right: ${props => props.theme.size.spacing_2};
`

export const CssXS = styled.span`
    color      : ${props => props.wcriteria ? 
                ( props.wtype === "uv" ? props.theme.colors.text.ipma.uv[props.wcriteria] : props.theme.colors.text.ipma[props.wcriteria]) : 
                props.theme.colors.text.value};
    font-size  : 19.2px;
    line-height: 24px;
`

export const CssData = styled.div``

export const CssValue = styled.div`
    font-size  : 33px;
    line-height: 40px;
    font-weight: ${({theme}) => theme.text.weight.bold};
    color      : ${props => props.wcriteria ? 
                ( props.wtype === "uv" ? props.theme.colors.text.ipma.uv[props.wcriteria] : props.theme.colors.text.ipma[props.wcriteria]) : 
                props.theme.colors.text.value};

    // ANIMATION IF A  "wcriteria" IS TRUE
    animation-name: ${({ wcriteria }) => wcriteria && pulseAnime};
    ${({ wcriteria, theme }) => wcriteria && `
        animation-duration: 2s;
        animation-iteration-count: infinite;
    `};
`

export const CssTag = styled.div`
    color      : ${props => props.theme.colors.text.tag};
    font-size  : 19.2px;
    line-height: ${props => props.theme.size.layout_2};
    min-height : ${props => props.theme.size.layout_2};
    font-weight: ${props => props.theme.text.weight.regular};
`
export const CssEmptyTag = styled.div`
    color: ${props => props.theme.colors.text.tag};
`

export const CssCol = styled.div`
    display       : flex;
    flex-direction: column;
    align-items   : flex-start;
    margin-top    : ${props => props.theme.size.spacing_6};
    margin-bottom : ${props => props.theme.size.spacing_4};
    margin-right  : ${props => props.theme.size.spacing_4};

`

export const CssTitle = styled.div`
    font-size  : 19px;
    line-height: 24px;
    color      : ${props => props.theme.colors.text.default_2};
    font-weight: ${props => props.theme.text.weight.bold};
`

export const CssTitleSubInfo = styled.span`
    color      : ${props => props.theme.colors.text.default_2};
    font-weight: ${props => props.theme.text.weight.regular};
`


export const CssBox = styled.div`
    background-color: ${props => props.theme.colors.bg.box.default};
    padding         : ${props => props.theme.size.spacing_6};         //24px;
    border-radius   : ${props => props.theme.radius.medium};          // 8px;
    position: relative;
`

export const CssBoxXL = styled.div`
    background-color: ${props => props.theme.colors.bg.box.default};
    padding         : ${props => props.theme.size.spacing_6};         //24px;
    border-radius   : ${props => props.theme.radius.medium};          // 8px;
    grid-column     : span 1;
    grid-row        : span 1;
    
    @media (min-width:  ${props => 
                        parseFloat(props.theme.size.layout_10)*2+
                        parseFloat(props.theme.size.spacing_6)*2+
                        parseFloat(props.theme.size.spacing_5)+"px" } ) { 
                        grid-column: span 2;
                        grid-row   : span 1;
    }
`
export const CssAlertBox = styled.div`
	background-color: ${props => props.theme.colors.bg.box.default};
	padding         : ${props => props.theme.size.spacing_6};         //24px;
	border-radius   : ${props => props.theme.radius.medium};          // 8px;
	grid-column     : auto / span 1;
	grid-row        : auto / span 2;

    @media (min-width:  ${props => 
                        parseFloat(props.theme.size.layout_10)*2+
                        parseFloat(props.theme.size.spacing_6)*2+
                        parseFloat(props.theme.size.spacing_5)+"px" } ) { 
                        grid-column: auto / span 2;
                        grid-row   : auto / span 2;
    }
`



/**
 * OfflineMessage
 */

export const CssBackground = styled.div`
    background-color: ${props => props.theme.colors.bg.box.info};
    width           : 94%;
    height          : 70%;
    opacity         : 0.8;
    top             : 25%;
    left            : 3%;
    position        : absolute;
    border-radius   : ${props => props.theme.radius.medium};
`

export const CssOffMsg = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    margin         : -${props => props.theme.size.spacing_6};

    
    &>p{
        color: ${props => props.theme.colors.text.tag};
        border: 1px ${props => props.theme.colors.text.tag} solid;
        font-weight: ${props => props.theme.text.weight.regular};
        opacity:1;
        margin: auto;
        font-size: 20px;
        width: 50%;
        text-align: center;
        padding: 10px;
        position:absolute;
        z-index: 100;
        top: 60%;
        left: 50%;
        transform: translate(-50%, -60%); 
    }
`

export const CsSmallDateTime = styled.p`
    color: ${props => props.theme.colors.text.tag};
    font-weight: ${props => props.theme.text.weight.regular};
    margin: 0;
    font-size  : 16px;
`