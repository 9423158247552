import { useState, useEffect } from 'react';
import moment from 'moment';
import c from '../constants/config';
import { useGetAPI } from './useGetAPI';

function getWeatherItemByHour(day, weatherItem, hour = 0) {
  const precipitationProbability100Str = day.find(
    (item) =>
      moment(item.dataPrev).get('hour') ===
        moment().add(hour, 'hours').get('hour') && item[weatherItem]
  )?.[weatherItem];
  // eslint-disable-next-line eqeqeq
  if (precipitationProbability100Str == undefined) {
    return getWeatherItemByHour(day, weatherItem, hour - 1);
  }
  return precipitationProbability100Str;
}

function constructForcastDay(day) {
  const precipitationProbability100Str = getWeatherItemByHour(
    day,
    'probabilidadePrecipita'
  );
  const precipitationProbability100 =
    Number(precipitationProbability100Str) ?? undefined;
  const windSpeedStr = getWeatherItemByHour(day, 'ffVento');
  const windSpeed = Number(windSpeedStr);
  const uVIndex= day.find((item) => item.iUv) && Number(day.find((item) => item.iUv)) ? day.find((item) => item.iUv).iUv : undefined;
  return {
    dayMaximum: {
      temperature: Number(day.find((item) => item.tMax).tMax) ?? undefined
    },
    dayMinimum: {
      temperature: Number(day.find((item) => item.tMin).tMin) ?? undefined
    },
    windSpeed,
    precipitationProbability:
      precipitationProbability100 || precipitationProbability100 === 0
        ? precipitationProbability100 / 100
        : undefined,
    uVIndexMax: uVIndex,
    validFrom: day[0]?.dataPrev
  };
}

function detectNextHourMark(dateString) {
  const currentDate = dateString ? new Date(dateString) : new Date();
  const __currentHr = currentDate.getHours();
  const nextHour =
    __currentHr >= 9 ? __currentHr + 1 : '0' + (__currentHr + 1).toString();
  const currentDateStr = currentDate.toISOString();
  const tzOffset = currentDate.getTimezoneOffset() * 60000; // timezone offset in ms
  const nextHourMark = new Date(
    new Date(currentDateStr.slice(0, 11) + nextHour + ':00:00.000Z')
  );
  const timeToNextHr = nextHourMark - currentDate;
  return timeToNextHr + tzOffset;
}
let intervalId, timeoutId;

function hourmarkHandler({ next, next2 }, cb) {
  const nextDay = next ? constructForcastDay(next) : {};
  const next2Day = next2 ? constructForcastDay(next2) : {};
  cb({ nextDay, next2Day });
  intervalId = setInterval(() => {
    const nextDay = next ? constructForcastDay(next) : {};
    const next2Day = next2 ? constructForcastDay(next2) : {};
    cb({ nextDay, next2Day });
  }, 3600000); // There are 3600000 ms in 1 hour
}

export const useIpmaForecast = () => {
  const [forecasts, setForecasts] = useState({});
  const [forecast2Days, setForecast2Days] = useState({
    nextDay: {},
    next2Day: {}
  });

  const { data, error, isLoading } = useGetAPI(c.api.ipmaAggregateForecast, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false
  });

  useEffect(() => {
    if (!isLoading && !error) {
      const temp = { next2Day: [], nextDay: [] };
      data.forEach((item) => {
        if (
          moment().add(1, 'day').format('DD/MM/yyy') ===
          moment(item.dataPrev).format('DD/MM/yyy')
        ) {
          if (Number(item.probabilidadePrecipita) < 0) {
            const validPrecipitationProbability = temp.nextDay.findLast(
              (itm) => Number(itm.probabilidadePrecipita) >= 0
            ).probabilidadePrecipita;
            temp.nextDay.push({
              ...item,
              probabilidadePrecipita: validPrecipitationProbability
            });
          } else {
            temp.nextDay.push(item);
          }
        }
        if (
          moment().add(2, 'days').format('DD/MM/yyy') ===
          moment(item.dataPrev).format('DD/MM/yyy')
        ) {
          if (Number(item.probabilidadePrecipita) < 0) {
            const validPrecipitationProbability = temp.next2Day.findLast(
              (itm) => Number(itm.probabilidadePrecipita) >= 0
            ).probabilidadePrecipita;
            temp.next2Day.push({
              ...item,
              probabilidadePrecipita: validPrecipitationProbability
            });
          } else {
            temp.next2Day.push(item);
          }
        }
      });
      setForecasts(temp);
      const nextDay = temp.nextDay ? constructForcastDay(temp.nextDay) : {};
      const next2Day = temp.next2Day ? constructForcastDay(temp.next2Day) : {};
      setForecast2Days({ next2Day, nextDay });
    }
    if (error) {
      console.error(error);
    }
  }, [data, error, isLoading]);

  useEffect(() => {
    const intervalToNextHourmark = detectNextHourMark();
    if (!isLoading && forecasts.nextDay) {
      timeoutId = setTimeout(() => {
        hourmarkHandler(
          { next: forecasts.nextDay, next2: forecasts.next2Day },
          setForecast2Days
        );
      }, intervalToNextHourmark);
    }
    return () => {
      if (intervalId) clearTimeout(intervalId);
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [forecasts, isLoading]);

  return forecast2Days;
};
