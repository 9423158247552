import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import moment from 'moment-timezone'
import 'moment/min/locales'
moment.locale('pt')

export const useNavClock = () => {
    const [clock, setClock] = useState(moment())

    useEffect(() => {

        const interval = setInterval(() => {
            setClock(moment())
        }, 1000);
     
        return () => clearInterval(interval)
      }, [])

    //   return {clock}
	return (
		<CssWrapper>
			<CssWeekDay>{clock.format('dddd')} </CssWeekDay> 
			<CssDay className="normal">{clock.format('DD[-]MM[-]YYYY')} </CssDay> 
			<CssHour>{clock.format('HH:mm:ss')}</CssHour>
		</CssWrapper>
	)
}


/**
 * styled
 */

const CssWrapper = styled.div`
   color: #ffffff;
   display: flex;
   flex-direction: row;
   font-size: 18px;
   &>div{
    margin-right  : ${props => props.theme.size.spacing_4};
   }
`

const CssWeekDay = styled.div`

   color: ${props => props.theme.colors.text.default_2};
   font-weight: ${props => props.theme.text.weight.bold};
`

const CssDay = styled.div`
   color: ${props => props.theme.colors.text.default_2};
`
const CssHour = styled.div`
   color: ${props => props.theme.colors.text.default_2};
`
