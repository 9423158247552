import React from 'react'
import PropTypes from 'prop-types' 

import { TitleH3, InfoTagValue } from '../../../../components/UI/UI'
import {CssSideBar, CssSideBarHeader, CssBox, CssBoxWrapper} from './SideBar-style'

export const SideBar = ({name, temperature,  windSpeed, solarRadiation,  precipitation,  atmosphericPressure, sendShow, event, uVIndex, uVIndexMax}) => {
    
    const activate = () => event(false)
    
    return (
        <CssSideBar showSideBar={sendShow} >
            <CssSideBarHeader >
                <button onClick={activate}>x</button>
            </CssSideBarHeader>
            <TitleH3 title={`Estação meteorológica ${name}`} />

            <CssBoxWrapper>
                 {/* <TitleP title="Temperatura" />	 */}
                <CssBox>
                    <InfoTagValue tag="Temperatura" value={`${temperature ? temperature.toFixed(1) : temperature} ºC`}/>
                </CssBox>
                
                {/* <TitleP title="Vento" />	 */}
                <CssBox>
                    <InfoTagValue tag="Vento" value={`${Math.round(windSpeed * 36) / 10} km/h`}/>
                </CssBox>
                {/* <TitleP title="Radiação UV" />	 */}
                <CssBox>
                    <InfoTagValue tag="Índice UV" value={`${uVIndexMax} UV`} />
                </CssBox>
                {/* <TitleP title="Precipitação" />	 */}
                <CssBox>
                    <InfoTagValue tag="Precipitação" value={`${precipitation} mm`}/>
                </CssBox>
                </CssBoxWrapper>
                {/* <TitleP title="Pressão" />	 */}
                <CssBox>
                    <InfoTagValue tag="Pressão atmosférica" value={`${atmosphericPressure ? atmosphericPressure.toFixed(1) : atmosphericPressure } mbar`} />
                </CssBox>

            
        </CssSideBar>
    )
}

SideBar.propTypes = {
        name: PropTypes.string,
        temperature: PropTypes.number,
        solarRadiation: PropTypes.number,
        windSpeed: PropTypes.number,
        atmosphericPressure: PropTypes.number,
        precipitation: PropTypes.number,
        uVIndexMax: PropTypes.number,

}

SideBar.defaultProps = {
        name: "Rua ...",
        temperature: 0,
        solarRadiation: 0,
        windSpeed: 0,
        atmosphericPressure: 0,
        precipitation: 0,
        uVIndexMax: 0,
 
}
