import { object, array, string, number } from "yup";

export const weatherHistoryAlertSchema = object().shape({
  entity_type: string()
    .equals(["Alert"], "Entity type is not 'Alert'")
    .required(),
  request_params: object()
    .shape({
      page: number().required(),
      total_records: number().required(),
    })
    .required(),
  request_entities: array()
    .of(
      object().shape({
        description: string().required(),
        id: string().required(),
        validFrom: string().required(),
        validTo: string().required(),
      })
    )
    .required(),
});
