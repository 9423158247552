import React, { createContext, useState } from "react"
import { ApdTheming } from '@apd/designtoken'

export const AppContext = createContext(null)

export const AppProvider = props => {
    const [isClock, setClock] = useState({ time: "2001/01/01 00:00:00" })

    return (
        <ApdTheming>
            <AppContext.Provider value={{ 
                isClock: isClock, 
                setClock: setClock,
                }}>
                {props.children}
            </AppContext.Provider>
        </ApdTheming>
    )
} 
