import React from 'react'
import { withTheme } from 'styled-components'

import {
    CssRow,
    CssType,
    CssRowSize,
    CssCellFooter,
} from './IpmaTimeline-style'

import moment from 'moment'
import 'moment/min/locales'
moment.locale('pt')

const RowFooter = ({ hoursArray, currentDate }) => {
    let dayArray = []
    return (
        <CssRowSize>
            <CssType />
            <CssRow>
                {hoursArray.map((hour, i) => {
                    let _thisDay = ""
                    if (hour === "00") {
                        _thisDay = moment(currentDate).add(dayArray.length + 1, "days").format("DD MMM")
                        dayArray.push(_thisDay)
                    }

                    return <CssCellFooter hour={hour} day={_thisDay} key={"footer" + i} />
                })}
            </CssRow>
        </CssRowSize>
    )
}

export default withTheme(RowFooter)
