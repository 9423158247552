import { useEffect, useState } from 'react';

export function useIsMobile() {
  const [isMobileScreen, setIsMobileScreen] = useState(false);

  useEffect(() => {
    if (window.innerWidth < 721) {
      setIsMobileScreen(true);
    }
    window.onresize = (ev) => {
      if (window.innerWidth < 721) {
        setIsMobileScreen(true);
      } else {
        setIsMobileScreen(false);
      }
    };
  }, []);
  return isMobileScreen;
}
