import styled from 'styled-components'

export const CssSideBar = styled.div`
    position: absolute;
    display: inline-table;
    width: ${props => props.theme.size.layout_9};
    height: 100px;
    border: 1px #e1e1e1 solid;
    top: 0;
    right: ${props => props.showSideBar ? "0" : "-"+props.theme.size.layout_10};
    z-index: 100000;
    background-color: rgba(255,255,255,0.8);
    margin: ${props => props.theme.size.spacing_6}; 
    padding: ${props => props.theme.size.spacing_5}; 
    transition:right 0.3s ease-out;
    text-transform: initial;

`


export const CssBoxWrapper = styled.div`
  display: grid;
  grid-template-columns: 50% 50% ;
  grid-template-rows: auto;
`
export const CssBox = styled.div`
    margin-top: 14px;
    text-transform: initial;
`

export const CssSideBarHeader = styled.div`
  
    align-items: flex-end;
    flex-direction: column;
    display: flex;
    font-style: none;
    
    & a{
        text-decoration: none;
        color: #595959;
    }

`