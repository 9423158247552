import React from 'react'
import { CssNoAlertsText, CssWrapper } from './MessageNoWarning-style'


export const MessageNoWarning = () => (
    <CssWrapper>
        <CssNoAlertsText>
            <p>Sem avisos meteorológicos.</p>
        </CssNoAlertsText>
    </CssWrapper>
)