import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`

  body {
        margin        : 0;
        padding       : 0;
        font-family   : Roboto;
        font-weight: 400;
        background-color: ${(props) => props.theme.colors.bg.body.default};
    }
`;

export const CssMain = styled.main`
  flex: 1 1 0%;
  position: relative;
  margin-top: 3.6rem;
`;

export const CssBody = styled.div`
  margin: 0;
  padding: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  > div > header {
    z-index: 99999;
    height: 56px;
  }
`;

export const CssLogoApd = styled.div`
  width: ${(props) => props.theme.size.layout_8};
  height: ${(props) => props.theme.size.layout_3};
  padding-top: ${(props) => props.theme.size.spacing_4};
  padding-bottom: ${(props) => props.theme.size.spacing_4};
  padding-left: ${(props) => props.theme.size.spacing_1};
  padding-right: ${(props) => props.theme.size.spacing_1};
`;

export const CssWrapper = styled.div`
  height: 100%;
  display: flex;
  /* justify-content: flex-end; */
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  position: absolute;
  right: 0;
`;
