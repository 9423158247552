
const temp = value => {
    let msg = null
    const _value = Number(value)
    // Minimum Temperature
    if (_value >= -1 && _value <= 1)  msg = "medium"
    if (_value >= -3 && _value < -2)  msg = "high"
    if (_value < -3)  msg = "critical"
    // Maximum Temperature
    if (_value >= 32 && _value <= 36.9)  msg = "medium"
    if (_value >= 37.0 && _value <= 38.0)  msg = "high"
    if (_value > 38.0)  msg = "critical"

    if(msg === null) return
    
    return msg
}


const wind = value => {
    let msg = null
    // Maximum Gust	
    if (value > 70 && value < 90)  msg = "medium"
    if (value > 90 && value < 130)  msg = "high"
    if (value > 130)  msg = "critical"
    
    return msg
}

const rain_1h = value => {
    let msg = null
    // Rain/Showers		
    if (value >= 10 && value <= 20)  msg = "medium"
    if (value >= 21 && value <= 40)  msg = "high"
    if (value > 40)  msg = "critical"

    return msg
}

const rain_6h = value => {
    let msg = null
    // Rain/Showers		
    if (value >= 30 && value <= 40)  msg = "medium"
    if (value >= 41 && value <= 60)  msg = "high"
    if (value > 60)  msg = "critical"

    return msg
}

const uv = value => {
    let msg = null
    // uv index	
    if (value >= 3 && value < 6)  msg = "medium"
    if (value >= 6 && value < 9)  msg = "high"
    if (value >= 9 && value <= 11)  msg = "veryhigh"
    if (value > 11)  msg = "maximum"

    return msg
}

export const criteria = {
    temp, wind, rain_1h, rain_6h, uv
}