import { useState, useEffect } from 'react'
import axios from 'axios'
import c from '../constants/config'
import moment from 'moment'
import 'moment/min/locales'
moment.locale('pt')

/**
 * THIS SERVICE NEED TO PERFORM MULTIPLE REQUESTS 
*/

const setup = {
    dateFormat: "YYYY-MM-DDTHH:mm:ss.000"
}

export const useWeatherStationHistoricalData = () => {

    const [_maxTemperature, set_MaxTemperature] = useState(null)
    const [_minTemperature, set_MinTemperature] = useState(null)
    const [_maxWind, set_MaxWind] = useState(null)
    const [_lastHourPrecipitation, set_LastHourPrecipitation] = useState(null)
    const [_lastSixPrecipitation, set_LastSixPrecipitation] = useState(null)

    const [entityId, setEntityId] = useState(null)
    const [reqHist, setReqHist] = useState(false)


    useEffect(() => {
        if (!reqHist) return null

        const dayStart              = moment().startOf('day').format(setup.dateFormat)
        const dayEnd                = moment().endOf('day').format(setup.dateFormat)
        const maxTemp               = `${c.api.weatherObservedHistory}?entities_ids=${entityId}&attribute_name=temperature&aggr_method=max&aggr_period=day&from_date=${dayStart}&to_date=${dayEnd}`
        const minTemp               = `${c.api.weatherObservedHistory}?entities_ids=${entityId}&attribute_name=temperature&aggr_method=min&aggr_period=day&from_date=${dayStart}&to_date=${dayEnd}`
        const maxWind               = `${c.api.weatherObservedHistory}?entities_ids=${entityId}&attribute_name=windSpeed&aggr_method=max&aggr_period=day&from_date=${dayStart}&to_date=${dayEnd}`
        const now                   = moment().format(setup.dateFormat)
        const lessOne               = moment().subtract(1, "hour").format(setup.dateFormat)
        const lessSix               = moment().subtract(6, "hours").format(setup.dateFormat)
        const lastHourPrecipitation = `${c.api.weatherObservedHistory}?entities_ids=${entityId}&attribute_name=precipitation&aggr_method=sum&aggr_period=day&from_date=${lessOne}&to_date=${now}`
        const lastSixPrecipitation  = `${c.api.weatherObservedHistory}?entities_ids=${entityId}&attribute_name=precipitation&aggr_method=sum&aggr_period=day&from_date=${lessSix}&to_date=${now}`
        
        axios.all([
            axios.get(maxTemp),
            axios.get(minTemp),
            axios.get(maxWind),
            axios.get(lastHourPrecipitation),
            axios.get(lastSixPrecipitation),
        ])
            .then(axios.spread((objMaxTemp, objMinTemp, objMaxWind, objLastHourPrecipitation, objLastSixPrecipitation) => {

                if(objMaxTemp.data.request_entities)
                    set_MaxTemperature(objMaxTemp.data.request_entities[0].request_data[0].value)
                if(objMinTemp.data.request_entities)
                    set_MinTemperature(objMinTemp.data.request_entities[0].request_data[0].value)
                if(objMaxWind.data.request_entities)
                    set_MaxWind(objMaxWind.data.request_entities[0].request_data[0].value)
                if(objLastHourPrecipitation.data.request_entities)
                    set_LastHourPrecipitation(objLastHourPrecipitation.data.request_entities[0].request_data[0].value)
                if(objLastSixPrecipitation.data.request_entities)
                    set_LastSixPrecipitation(objLastSixPrecipitation.data.request_entities[0].request_data[0].value)

                setReqHist(false)
            }))
            .catch(err => {
                setReqHist(false)
                console.error("ERROR from API on Weater Station Historical Data With error: ", err)
            })


    }, [entityId, reqHist])

    return {
        _maxTemperature, 
        _minTemperature,
        _maxWind,
        _lastHourPrecipitation,
        _lastSixPrecipitation,
        setReqHist, 
        setEntityId, 
        entityId
    }
}
