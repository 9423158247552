import styled from 'styled-components'

export const CssWrapper = styled.div`
	position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
`
export const CssMap = styled.div`
	position: absolute;
    width: 100%;
    height: 100%;
`

export const CssDropWrapper = styled.div`
    background-color: white;
    position: absolute;
    z-index: 1000;
    top: 16px;
    left: 8px;

`