import styled from 'styled-components';

export const CssContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  height: auto;
  padding: 0px;
  flex-wrap: wrap;
`;

export const CssArea = styled.div`
  color: ${(props) => props.theme.colors.text.category};
  margin-bottom: ${(props) => props.theme.size.spacing_5};
  line-height: ${(props) => props.theme.size.layout_2};
`;

export const CssIcon = styled.div`
  width: ${(props) => props.theme.size.layout_2};
  height: ${(props) => props.theme.size.layout_2};
  float: left;
  margin-right: ${(props) => props.theme.size.spacing_2};
`;

export const CssXS = styled.span`
  font-size: 19.2px;
  line-height: 24px;
`;

export const CssData = styled.div``;

export const CssRow = styled.div`
  margin-top: ${(props) => props.theme.size.spacing_4};
  display: flex;
  flex-direction: row;
  width: 100%;
  /* justify-content: space-between; */
  & > ${CssData} {
    width: 95px;
  }
`;

export const CssValue = styled.div`
  font-size: 33px;
  line-height: 40px;
  font-weight: ${(props) => props.theme.text.weight.bold};
  color: ${(props) =>
    props.wcriteria
      ? props.wtype === 'uv'
        ? props.theme.colors.text.ipma.uv[props.wcriteria]
        : props.theme.colors.text.ipma[props.wcriteria]
      : props.theme.colors.text.value};
`;

export const CssValueXS = styled(CssValue)`
  font-size: 23px;
  line-height: 24px;
  color: ${(props) =>
    props.wcriteria
      ? props.wtype === 'uv'
        ? props.theme.colors.text.ipma.uv[props.wcriteria]
        : props.theme.colors.text.ipma[props.wcriteria]
      : props.theme.colors.text.value};

  font-weight: ${(props) => props.theme.text.weight.bold};
  & > ${CssXS} {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const CssTag = styled.div`
  color: ${(props) => props.theme.colors.text.tag};
  line-height: ${(props) => props.theme.size.layout_2};
  min-height: ${(props) => props.theme.size.layout_2};
  font-weight: ${(props) => props.theme.text.weight.regular};
  font-size: 19.2px;
`;
export const CssEmptyTag = styled.div`
  color: ${(props) => props.theme.colors.text.tag};
`;

export const CssCol = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  align-items: flex-start;
  margin-top: ${(props) => props.theme.size.spacing_6};
  margin-bottom: ${(props) => props.theme.size.spacing_4};
  margin-right: ${(props) => props.theme.size.spacing_4};
`;

export const CssTitle = styled.div`
  font-size: 19px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.text.default_2};
  font-weight: ${(props) => props.theme.text.weight.bold};
`;

export const CssBox = styled.div`
  background-color: ${(props) => props.theme.colors.bg.box.default};
  padding: ${(props) => props.theme.size.spacing_6}; //24px;
  border-radius: ${(props) => props.theme.radius.medium}; // 8px;
  @media screen and (max-width: 720px) {
    padding-top: 50px;
    height: 80vh;
    overflow-y: scroll;
  }
`;

export const CssButton = styled.div`
  display: flex;
  span {
    margin-left: auto;
  }
`;
