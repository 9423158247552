import styled from 'styled-components'

export const CssIconTable = styled.div`
    border-left: 2px solid ${props => props.theme.colors.bg.ipma[props.severity]};
    padding-left: ${props => props.theme.size.spacing_4};
`
export const CssTimeViewDate = styled.div`
`
export const CssTimeViewHour = styled.div`
    color: ${props => props.theme.colors.text.tag};
`

export const CssIcon = styled.div`
    width: ${props => props.theme.size.layout_3};
    height: ${props => props.theme.size.layout_3};
    padding: ${props => props.theme.size.spacing_2};
`