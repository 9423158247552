import React, { Fragment } from 'react';
import { Marker, Tooltip } from 'react-leaflet';
import L from 'leaflet';

const markerSettings = {
  iconAnchor: [20, 20],
  // iconSize   : [60, 60],
  iconSize: [40, 40],
  popupAnchor: [0, -10]
};
// const markerSvgTemp = `
// <svg width="60px" height="60px" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg">
//     <!-- non-rotated arrow -->
//     <g id="arrow" style="stroke: rgba(0, 0, 0, 0.9);" transform="rotate( 0 , 30 , 30)">
//         <polygon points="25 20, 30 0, 35 20"/>
//     </g>

//     <!-- center of rotation -->
//     <circle cx="30" cy="30" r="21"  stroke="rgba(0, 0, 0, 0.9)" stroke-width="2" style="fill:{markerStatusColor};" />

//     <!-- VAlue at the middle of the circle -->
//     <text x="50%" y="50%" text-anchor="middle" fill="#000000" font-size="16px" font-family="Roboto" dy=".4em">{value}</text>
// </svg>

//         `
const markerSvg = `
    <svg width="40" height="40" viewBox="0 0 40 40"  fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20" cy="20" r="20" fill="rgba(0, 255,0,0)" />
    <svg x="10" y="10">
        <circle cx="10" cy="10" r="8" stroke="rgba(0, 0, 0, 0.6)" stroke-width="2" fill={markerStatusColor} />
    </svg>
    </svg>
        `;
const Icon = (props) => {
  let statusColor = getColor(props);
  const ico = L.divIcon({
    className: 'station-marker',
    html: L.Util.template(markerSvg, {
      markerStatusColor: statusColor,
      value: props
    }),
    iconAnchor: markerSettings.iconAnchor,
    iconSize: markerSettings.iconSize,
    popupAnchor: markerSettings.popupAnchor,
    tooltipAnchor: [0, 10],
    shadowSize: [40, 40],
    shadowAnchor: [10, 30],
    iconUrl: L.Icon.Default.imagePath + 'marker-icon.png',
    shadowUrl: L.Icon.Default.imagePath + 'marker-shadow.png'
  });
  return ico;
};

const WStationMakers = ({ data, event }) => {
  const evenSelect = (id) => event(id);

  let Rmarkers = Object.keys(data).map((id) => {
    const station = data[id];

    return (
      <Marker
        key={station.id}
        icon={Icon(station.temperature)}
        position={[
          station.location.coordinates[1],
          station.location.coordinates[0]
        ]}
        // onClick={(e) => evenSelect(station.id)}
        eventHandlers={{
          click: () => evenSelect(station.id)
        }}
      >
        <Tooltip direction='bottom' opacity={0.85} permanent>
          {station.temperature !== null
            ? station.temperature.toFixed(1) + 'ºC'
            : 'N/D'}
        </Tooltip>
      </Marker>
    );
  });

  return <Fragment>{Rmarkers}</Fragment>;
};

const getColor = (d) => {
  return d > 40
    ? '#9A339D'
    : d > 35
    ? '#681E23'
    : d > 35
    ? '#BE3A29'
    : d > 30
    ? '#BE3A29'
    : d > 25
    ? '#E46B46'
    : d > 20
    ? '#F3A962'
    : d > 15
    ? '#F8DD8D'
    : d > 10
    ? '#DBF1F8'
    : d > 5
    ? '#ABD3E3'
    : d > 0
    ? '#818BB9'
    : d > -5
    ? '#7C61A1'
    : d > -10
    ? '#773C93'
    : '#FFEDA0';
};

export default WStationMakers;
