import { useEffect, useRef, useState } from 'react';
import socketIOClient from 'socket.io-client';
import c from '../constants/config';
import { weatherObservedSchema } from '../schema';

const SOCKET_SERVER_URL = c.webSocket;

const SUBSCRIPTION = {
  id_pattern: 'urn:ngsi-ld:WeatherObserved:porto:weather:.*', //'ubiwhere',
  ngsi_type: 'WeatherObserved',
  attributes: '',
  event_name: 'getWeatherObserved'
};

export const useSocketWeatherObserved = () => {
  const [wStationList, setWStationList] = useState({});
  const socketRef = useRef();

  useEffect(() => {
    // Creates a WebSocket connection
    socketRef.current = socketIOClient(SOCKET_SERVER_URL, {
      transports: ['websocket', 'polling']
    });

    socketRef.current.on('connect', () =>
      socketRef.current.emit('addSubscription', SUBSCRIPTION)
    );

    socketRef.current.on(SUBSCRIPTION.event_name, (data) => {
      let updateStation = {};
      data.forEach((item) => {
        // UPDATE LIST OF STATION
        try {
          if (weatherObservedSchema.isValidSync(item)) {
            updateStation[item.id] = { ...item };
          } else {
            weatherObservedSchema
              .validate(item)
              .catch((error) => console.error({ error }));
          }
        } catch (e) {
          console.error(e);
        }
      });

      setWStationList((prevState) => ({
        ...prevState,
        ...updateStation
      }));
    });

    return () => socketRef.current.disconnect();
  }, []);

  return { wStationList };
};
