import React, { useState, useEffect } from 'react'
import axios from 'axios'
import c from '../../../constants/config'
import { GeoJSON } from "react-leaflet"


const geojsonStyle = { color: '#000F9F', weight: 1, opacity: 0.4, fillColor: '#FF0000', fillOpacity: 0, dashArray: '0' }

export const CityLimits = () => {
    const [geoJSON, setGeoJSON] = useState(null)

    useEffect(() => {
        axios.get(c.api.cityLimits)
            .then(r => setGeoJSON(r.data) )
            .catch(err => console.log("ERROR from setGeoJSON API: ", err) )
    }, [])

    if (!geoJSON) return null
    
    return (
        <GeoJSON 
            data={geoJSON} 
            key="geojson" 
            style={geojsonStyle} 
        /> 
    )
}