export default [
  {
    name: "Dashboard",
    route: "/dashboard",
  },
  {
    name: "Mapa",
    route: "/mapa",
  },
  {
    name: "Avisos",
    route: "/analitica/avisos",
  },
  {
    name: "Monitorização",
    route: "/analitica/monitorizacao",
  },
];
