import moment from 'moment'
import 'moment/min/locales'
moment.locale('pt')

export const GetArrayOfhour = ({currentDate, setup: {numberHours}}) => {
    const endDate = moment(currentDate).add(numberHours, 'hour')
    const diff = moment(endDate).diff(currentDate, "hours")

    const hoursArray = []

    for (let index = 0; index < diff; index++) {
        hoursArray.push(moment(currentDate).add(index, "hour").format("HH"))
    }
  
    return hoursArray
}

export const  BuildAlertTable = ({alerts, setup}) => {
    const warningsType = {
        "thunderstorms": "Trovoada",
        "coastalEvent": "Agitação Marítima",
        "rainfall": "Precipitação",
        "wind": "Vento",
        "snow": "Neve",
        "coldWave": "Tempo Frio",
        "heatWave": "Tempo Quente",
        "fog": "Nevoeiro"
    }

    const template = {}
    Array.from({ length: setup.numberHours }, (v, i) => template[i] = "low")

    const templateTable = {}
    Object.keys(warningsType).map(item => templateTable[item] = { ...template })

    const now = moment()
    const msg = {...templateTable}
  
    alerts.map(alert => {
        const startDiff = moment(alert.validFrom).diff(now, 'hours')
        const endDiff = moment(alert.validTo).diff(now, 'hours')

        if (startDiff < 0 && endDiff < 0) return null

        return Object.keys(msg[alert.subCategory]).map(key => {
            if (parseInt(key) > startDiff && parseInt(key) <= endDiff) {
                return msg[alert.subCategory][key] = alert.severity
            } else {
                return msg[alert.subCategory][key]
            }
        })
    })

    return msg
}