import React from 'react'
import { withTheme } from 'styled-components'

import {
    CssRow,
    CssType,
    CssCellHeader,
    CssRowSize,
    CssCellHeaderMarker,
    CssCellHeaderHour
} from './IpmaTimeline-style'

import moment from 'moment'
import 'moment/min/locales'
moment.locale('pt')

const RowHeader = ({ hoursArray, setup: { showHours, showSpaces } }) => (
    <CssRowSize>
        <CssType />
        <CssRow>
            {hoursArray.map((hour, i) => {
                const isHour = (showHours).includes(hour)
                const isSpace = (showSpaces).includes(hour)

                if (isHour) {
                    return <CssCellHeaderHour hour={hour} key={i} />
                } else if (isSpace) {
                    return <CssCellHeaderMarker key={"space" + i} />
                } else {
                    return <CssCellHeader key={"header" + i} />
                }
            }
            )}
        </CssRow>
    </CssRowSize>
)

export default withTheme(RowHeader)
