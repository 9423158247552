import React, { useState, useEffect } from 'react';
import { TitleH3, TitleBox } from '../../../components/UI/UI';
import DropdownDatepicker from '@apd/dropdown-datepicker';
import {
  ChartTemperature,
  ChartWind,
  ChartUV,
  ChartPrecipitation,
  DropdownStation,
  GetAggrPeriod
} from './MonitoringComponents';
import './MonitoringScreen.css';

import moment from 'moment';
import 'moment/min/locales';
import { useIsMobile } from '../../../hooks/useIsMobile';

moment.locale('pt');
const formatT = 'YYYY-MM-DD[T]H:mm:ss.000';

// var _paq = (window._paq = window._paq || []);
// /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
// _paq.push(['trackPageView']);
// _paq.push(['enableLinkTracking']);
// (function () {
//   var u = 'https://matomo.porto.digital/';
//   _paq.push(['setTrackerUrl', u + 'matomo.php']);
//   _paq.push(['setSiteId', '15']);
//   var d = document,
//     g = d.createElement('script'),
//     s = d.getElementsByTagName('script')[0];
//   g.async = true;
//   g.src = u + 'matomo.js';
//   s.parentNode.insertBefore(g, s);
// })();

const MonitoringScreen = () => {
  const [stationID, setStationID] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const isMobile = useIsMobile();

  const selectStation = (id) => setStationID(id);

  const selectTimeFrame = (time) => {
    setFromDate(
      moment(time.startDate).startOf('day').format(formatT).toString()
    );
    setToDate(moment(time.endDate).endOf('day').format(formatT).toString());
  };

  useEffect(() => {
    document.title = 'Monitorização | PMGM';
  }, []);

  return (
    <div className='analytics-page'>
      <div className='page-header'>
        <div className='alighL'>
          <div className='title-icon'>
            <svg
              width='16'
              height='12'
              viewBox='0 0 16 12'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M10.375 8H11.5938C11.7812 8 12 7.8125 12 7.625V3.40625C12 3.21875 11.7812 3 11.5938 3H10.375C10.1875 3 10 3.21875 10 3.40625V7.625C10 7.8125 10.1875 8 10.375 8ZM13.375 8H14.5938C14.7812 8 15 7.8125 15 7.625V0.40625C15 0.21875 14.7812 0 14.5938 0H13.375C13.1875 0 13 0.21875 13 0.40625V7.625C13 7.8125 13.1875 8 13.375 8ZM4.375 8H5.59375C5.78125 8 6 7.8125 6 7.625V5.40625C6 5.21875 5.78125 5 5.59375 5H4.375C4.1875 5 4 5.21875 4 5.40625V7.625C4 7.8125 4.1875 8 4.375 8ZM7.375 8H8.59375C8.78125 8 9 7.8125 9 7.625V1.40625C9 1.21875 8.78125 1 8.59375 1H7.375C7.1875 1 7 1.21875 7 1.40625V7.625C7 7.8125 7.1875 8 7.375 8ZM15.5 10H2V0.5C2 0.25 1.75 0 1.5 0H0.5C0.21875 0 0 0.25 0 0.5V11C0 11.5625 0.4375 12 1 12H15.5C15.75 12 16 11.7812 16 11.5V10.5C16 10.25 15.75 10 15.5 10Z'
                fill='#000C7F'
              />
            </svg>
          </div>
          <TitleH3 title='Analítica/Monitorização' />
        </div>

        <div className='alighR'>
          <DropdownDatepicker
            labelInfo='Período selecionado'
            dropdownTitle='Intervalo padrão'
            onEvent={selectTimeFrame}
            dateStart={moment().locale('pt').startOf('day')}
            dateEnd={moment().locale('pt').endOf('day')}
            dateBigBang={moment('01-01-2021').locale('pt')}
            isMobile={isMobile}
          />

          <DropdownStation event={selectStation} />
        </div>
      </div>

      <div className='box-wrapper'>
        <TitleBox title='Temperatura do ar [ºC]' />

        <ChartTemperature
          from_date={fromDate}
          to_date={toDate}
          aggr_period={GetAggrPeriod({ fromDate, toDate })}
          station_id={stationID}
        />
      </div>

      <div className='box-wrapper'>
        <TitleBox title='Vento [km/h]' />

        <ChartWind
          from_date={fromDate}
          to_date={toDate}
          aggr_period={GetAggrPeriod({ fromDate, toDate })}
          station_id={stationID}
        />
      </div>
      <div className='box-wrapper'>
        <TitleBox title='Precipitação [mm]' />

        <ChartPrecipitation
          from_date={fromDate}
          to_date={toDate}
          aggr_period={GetAggrPeriod({ fromDate, toDate })}
          station_id={stationID}
        />
      </div>
      <div className='box-wrapper'>
        <TitleBox title='Índice UV' />

        <ChartUV
          from_date={fromDate}
          to_date={toDate}
          aggr_period={GetAggrPeriod({ fromDate, toDate })}
          station_id={stationID}
        />
      </div>
    </div>
  );
};

export default MonitoringScreen;
