import React, { useContext, useEffect, useRef, useState } from 'react';
import { ThemeContext } from '@apd/designtoken';

import { StationBox } from './Components/StationBox';
import { FullStationBox } from './Components/FullStationBox';

import {
  CssWStationwrapper,
  CssBody,
  CssMainStation,
  CssSmallStation,
  CssWarningWrapper,
  CssForcastWrapper,
  CssWarningMsgWrapper,
  CssIPMAwrapper,
  CssBottom,
  CssTop,
  CssScale
} from './DashboardTVScreen-style';

import { CssAlertBox, CssTitle } from './Components/StationBox-style';

import c from '../../constants/config';
import axios from 'axios';

import { IpmaTimeline } from '../../components/IpmaTimeline/IpmaTimeline';
import { IpmaWarning } from '../../components/IpmaWarningsBox/IpmaWarningCarouselAdvance';
import { NextDayBox } from './Components/NextDayBox';

import { useSocketWeatherObserved } from '../../hooks/useSocketWeatherObserved';

import { useImpaActiveAlerts } from '../../hooks/useAtiveIpmaAlerts';
// import { useNextDayForecast } from '../../hooks/useNextDayForecast'
// import { useNext2DayForecast } from '../../hooks/useNext2DayForecast'
import { useIpmaForecast } from '../../hooks/useIpmaForecast';

// var _paq = (window._paq = window._paq || []);
// /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
// _paq.push(['trackPageView']);
// _paq.push(['enableLinkTracking']);
// (function () {
//   var u = 'https://matomo.porto.digital/';
//   _paq.push(['setTrackerUrl', u + 'matomo.php']);
//   _paq.push(['setSiteId', '15']);
//   var d = document,
//     g = d.createElement('script'),
//     s = d.getElementsByTagName('script')[0];
//   g.async = true;
//   g.src = u + 'matomo.js';
//   s.parentNode.insertBefore(g, s);
// })();

/**
 * setup this component
 */

const setup = {
  timer2Show: 20000
};

const checkAppVersion = () => {
  axios
    .get(`${window.location.origin}/_api/version`)
    .then((r) => {
      if (r.statusText !== 'OK') return;
      if (r.data.version !== c.app_version) window.location.reload(false);
    })
    .catch((err) => console.log('ERROR checking app version: ', err));
};

/**
 *
 * @returns Screen dar
 */

const DashboardTvScreen = () => {
  // const { nextDay } = useNextDayForecast()
  // const { next2Day } = useNext2DayForecast()
  const { wStationList } = useSocketWeatherObserved();
  const { alertList, isLoading } = useImpaActiveAlerts();
  const [wsList, setWsList] = useState([]);

  // LIST Station -> rotation
  const [wsTopList, setWsTopList] = useState([]);
  const [wsBottomList, setWsBottomList] = useState([]);

  const { next2Day, nextDay } = useIpmaForecast();

  const increment = useRef(null);

  const { activeTheme, setActiveTheme } = useContext(ThemeContext);

  useEffect(() => {
    checkAppVersion();
    document.title = 'TV Dashboard | PMGM';
    if (activeTheme !== 'DarkTheme') setActiveTheme('DarkTheme');
    const interval = setInterval(() => {
      // setIsReq(true);
      checkAppVersion();
    }, c.timeInterval.dashboard);

    return () => {
      clearInterval(interval);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const sList = Object.keys(wStationList) || [];

    // check and update station list
    if (sList.length !== wsList.length) setWsList(sList);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wStationList]);

  useEffect(() => {
    if (wsList.length === 0) return;

    const [b0, b1, b2, b3, b4, b5, b6] = wsList;

    setWsTopList([b2, b1, b0, b6]);
    setWsBottomList([b3, b4, b5, b6]);

    increment.current = setInterval(() => {
      // loop elements
      const [first, ...rest] = wsList;
      setWsList([...rest, first]);
    }, setup.timer2Show);

    return () => clearInterval(increment.current);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wsList]);

  return (
    <CssBody>
      <CssIPMAwrapper>
        <CssWarningWrapper>
          {!isLoading ? (
            <CssAlertBox>
              <CssTitle>Avisos ({Object.keys(alertList).length})</CssTitle>
              <CssScale>
                <IpmaTimeline alerts={alertList} />
              </CssScale>
            </CssAlertBox>
          ) : null}
        </CssWarningWrapper>

        <CssForcastWrapper>
          {/* <NextHourBox {...nextHour} /> */}
          <NextDayBox {...nextDay} />
          <NextDayBox {...next2Day} />
        </CssForcastWrapper>
      </CssIPMAwrapper>

      {!isLoading ? (
        <CssWarningMsgWrapper>
          <IpmaWarning alerts={alertList} />
        </CssWarningMsgWrapper>
      ) : null}

      <CssWStationwrapper>
        <CssMainStation>
          <FullStationBox key={wsList[3]} {...wStationList[wsList[3]]} />
        </CssMainStation>

        <CssSmallStation>
          <CssTop>
            {wsTopList.map((el) => (
              <StationBox key={el} {...wStationList[el]} />
            ))}
          </CssTop>

          <CssBottom>
            {wsBottomList.map((el) => (
              <StationBox key={el} {...wStationList[el]} />
            ))}
          </CssBottom>
        </CssSmallStation>
      </CssWStationwrapper>
    </CssBody>
  );
};

export default DashboardTvScreen;
