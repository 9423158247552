import React, { useState } from "react";
import { BrowserRouter } from "react-router-dom";
// import TagManager from 'react-gtm-module'
import c from "../constants/config";

import {
  CssMain,
  CssBody,
  GlobalStyle,
  CssLogoApd,
  CssWrapper,
} from "./App-Style";
import Router from "../router/router";
import { Footer } from "@apd/footer";
import { NavBar } from "@apd/navbar";
import { useNavClock } from "../hooks/useNavClock";
import navigationData from "../constants/navigation";
import { AppProvider } from "../context";

import { MediaSvg } from "@apd/media-svg";
import { useEffect } from "react";


const App = () => {

  return (
    <BrowserRouter>
      <AppProvider>
        <GlobalStyle />
        <ContentWrapper />
      </AppProvider>
    </BrowserRouter>
  );
};

const ContentWrapper = () => {

  const [hideTVLayout, setHideTVLayout] = useState(false);
  const [forceReload, setForceReload] = useState(false);

  useEffect(() => {
      const tempState = window.location.pathname === "/dashboardtv" ? false : true;
      setHideTVLayout(tempState);

      if (!tempState) setForceReload(true);
  }, []);

  useEffect(() => {
    if (!forceReload) return;
    const interval = setInterval(() => {
      window.location.reload(false);
    }, 1000*60*10);

    return () => clearInterval(interval);
  }, [forceReload]);

  const clock = useNavClock();

  if (hideTVLayout) {
    return (
      <CssBody key='dashboard'>
        <NavBar data={navigationData} name="PMGM" />
        <CssMain>
          <Router />
        </CssMain>
        <Footer appName="PMGM" appVersion={c.app_version} />
      </CssBody>
    );
  } else {
    return (
      <CssBody key="dashboardtv">
        <NavBar name="Plataforma de Monitorização e Gestão Meteorológica">
          <CssWrapper>
            {clock}
            <CssLogoApd>
              <MediaSvg name="logo_apd" fill="#ffffff" />
            </CssLogoApd>
          </CssWrapper>
        </NavBar>

        <CssMain>
          <Router />
        </CssMain>
      </CssBody>
    );
  }
};

export default App;
