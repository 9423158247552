import React, { Suspense, Fragment } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import MapScreen from '../screens/MapScreen/MapScreen'
import DashboardScreen from '../screens/DashboardScreen/DashboardScreen'
import DashboardTVScreen from '../screens/DashboardScreen/DashboardTVScreen'
import { WarningScreen, MonitoringScreen } from '../screens/Analytic'

const Router = () => (
	<Fragment>
		<Suspense fallback={<div>Loading...</div>}></Suspense>
		<Switch>
			<Route exact path="/dashboard" component={DashboardScreen} />
			<Route exact path="/dashboardtv" component={DashboardTVScreen} />
			<Route exact path="/mapa" component={MapScreen} />
					
 			<Route exact path="/analitica/avisos" component={WarningScreen} />
 			<Route exact path="/analitica/monitorizacao" component={MonitoringScreen} />

			<Redirect from="/" to="/mapa" />
		</Switch>
	</Fragment>
)


export default Router