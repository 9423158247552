import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import PropTypes from 'prop-types'
import { MediaSvg } from '@apd/media-svg'
import { criteria } from '../../../components/ipmaHelper'

import { CssBoxXL, CssTitle, CssContent, CssCol, CssArea, CssTitleSubInfo, CssData, CssValue, CssTag, CssXS, CssIcon } from './StationBox-style'

import moment from 'moment'
import 'moment/min/locales'
moment.locale('pt')

export const NextDayBox = ({ dayMaximum, dayMinimum, windSpeed, precipitationProbability, uVIndexMax, validFrom }) => {
    const themeContext = useContext(ThemeContext)


    return (
        <CssBoxXL>
            <CssTitle>Previsão dia: <CssTitleSubInfo> {moment(validFrom).format("D MMM YYYY ")} </CssTitleSubInfo></CssTitle>
            <CssContent>
                <CssCol>
                    <CssArea>
                        <CssIcon>
                            <MediaSvg name={`ipma:temp`} fill={themeContext.colors.text.category} />
                        </CssIcon>
                        Temperatura
                    </CssArea>
                    <CssData>
                        <CssValue wcriteria={criteria.temp(dayMaximum.temperature)} >

                            {dayMaximum.temperature}

                            <CssXS wcriteria={criteria.temp(dayMaximum.temperature)} >ºC</CssXS>
                        </CssValue>
                        <CssTag>Máx.</CssTag>
                    </CssData>
                </CssCol>
                <CssCol>
                    <CssArea></CssArea>
                    <CssData>
                        <CssValue wcriteria={criteria.temp(dayMinimum.temperature)} >

                            {dayMinimum.temperature}

                            <CssXS wcriteria={criteria.temp(dayMinimum.temperature)}>ºC</CssXS>
                        </CssValue>
                        <CssTag>Mín.</CssTag>
                    </CssData>
                </CssCol>

                <CssCol>
                    <CssArea>
                        <CssIcon>
                            <MediaSvg name={`ipma:wind`} fill={themeContext.colors.text.category} />
                        </CssIcon>
                        Vento
                    </CssArea>
                    <CssData>
                        <CssValue wcriteria={criteria.wind(windSpeed) } >

                            {windSpeed ? windSpeed.toFixed(2) : "- "}

                            <CssXS wcriteria={criteria.wind(windSpeed)} >
                                km/h
                            </CssXS>
                        </CssValue>
                        <CssTag>Rajada máx.</CssTag>
                    </CssData>
                </CssCol>
                <CssCol>
                    <CssArea>
                        <CssIcon>
                            <MediaSvg name={`ipma:rainfall`} fill={themeContext.colors.text.category} />
                        </CssIcon>
                        Precipitação
                    </CssArea>
                    <CssData>
                        <CssValue>
                            
                            {precipitationProbability > 0 ? Math.round(precipitationProbability*100) : 0}
                            
                            <CssXS>%</CssXS>
                        </CssValue>
                        <CssTag>Prob. de chuva</CssTag>
                    </CssData>
                </CssCol>
                <CssCol>
                    <CssArea>
                        Radiação UV
                    </CssArea>
                    <CssData>
                        <CssValue wcriteria={criteria.uv(uVIndexMax)} wtype="uv">
                           
                            {uVIndexMax}
                            
                            <CssXS wcriteria={criteria.uv(uVIndexMax)} wtype="uv">UV</CssXS>
                        </CssValue>
                        <CssTag>Índice UV</CssTag>
                    </CssData>
                </CssCol>
            </CssContent>
        </CssBoxXL>
    )
}

NextDayBox.propTypes = {
    dayMaximum: PropTypes.shape({
        temperature: PropTypes.oneOfType([ PropTypes.string, PropTypes.number]),
    }),
    dayMinimum: PropTypes.shape({
        temperature: PropTypes.oneOfType([ PropTypes.string, PropTypes.number]),
    }),
    windSpeed: PropTypes.number,
    precipitationProbability: PropTypes.oneOfType([ PropTypes.string, PropTypes.number]),
    uVIndexMax: PropTypes.oneOfType([ PropTypes.string, PropTypes.number]),
    validFrom: PropTypes.string
}

NextDayBox.defaultProps = {
    dayMaximum: {
        temperature: "-"
    },
    dayMinimum: {
        temperature: "-"
    },
    windSpeed: 0,
    precipitationProbability: "-",
    uVIndexMax: "-",
    validFrom: "2000-01-01T00:00:00.00Z"
}