import moment from 'moment'

export const GetAggrPeriod = ({fromDate, toDate}) => {
	if(!fromDate || !toDate) return null

	const startT = moment(fromDate, "YYYY-MM-DD[T]H:mm:ss.000")
	const endT = moment(toDate, "YYYY-MM-DD[T]H:mm:ss.000")
	const hoursDiff = endT.diff(startT, 'hours')

	if (hoursDiff < 24 ) {
		return "hour"
	} else {
		return "day"
	}
}