import React, { useEffect, useState } from 'react';
import { CssTable, CssBodyWrapper } from './IpmaTimeline-style';
import { GetArrayOfhour, BuildAlertTable } from './helper';
import RowsWarning from './RowsWarning';
import RowHeader from './RowHeader';
import RowFooter from './RowFooter';

import moment from 'moment';
import 'moment/min/locales';
moment.locale('pt');

const setup = {
  numberHours: 70,
  showHours: ['00', '06', '12', '18'],
  showSpaces: ['03', '09', '15', '21']
};

const arrayTemplate = Array.from({ length: setup.numberHours }, (v, i) => i); // replace this

/**
 *
 *  IpmaTimeline
 *
 */

export const IpmaTimeline = ({ alerts }) => {
  const [currentDate, setCurrentDate] = useState(moment());
  const [hoursArray, setHourArray] = useState(arrayTemplate);
  const [alertTable, setAlertTable] = useState({});

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const now = moment();

    setAlertTable(BuildAlertTable({ alerts, setup }));
    setCurrentDate(now);
  }, [alerts]);

  useEffect(() => {
    const arr = GetArrayOfhour({ currentDate, setup });
    setHourArray(arr);
  }, [currentDate]);

  return (
    <CssTable>
      <RowHeader hoursArray={hoursArray} setup={setup} />
      <CssBodyWrapper>
        {Object.keys(alertTable).map((k) => {
          return (
            <RowsWarning
              key={k}
              type={k}
              hoursArray={hoursArray}
              alerts={alertTable[k]}
            />
          );
        })}
      </CssBodyWrapper>
      <RowFooter hoursArray={hoursArray} currentDate={currentDate} />
    </CssTable>
  );
};
