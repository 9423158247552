import React, { useEffect, useState } from 'react';
import c from '../../../constants/config';
import './WarningScreen.css';
import { TitleH3, TitleBox, DescriptionBox } from '../../../components/UI/UI'; //'../../components/UI/UI'
import SmartTable from './SmartTable';
import DropdownDatepicker from '@apd/dropdown-datepicker';
import Dropdown from '@apd/dropdown';

import moment from 'moment';
import 'moment/min/locales';
import { useIsMobile } from '../../../hooks/useIsMobile';

moment.locale('pt');
const formatT = 'YYYY-MM-DD[T]HH:mm:ss.000';

// var _paq = (window._paq = window._paq || []);
// /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
// _paq.push(['trackPageView']);
// _paq.push(['enableLinkTracking']);
// (function () {
//   var u = 'https://matomo.porto.digital/';
//   _paq.push(['setTrackerUrl', u + 'matomo.php']);
//   _paq.push(['setSiteId', '15']);
//   var d = document,
//     g = d.createElement('script'),
//     s = d.getElementsByTagName('script')[0];
//   g.async = true;
//   g.src = u + 'matomo.js';
//   s.parentNode.insertBefore(g, s);
// })();

const WarningScreen = () => {
  const [initDownloadRequest, setInitDownloadRequest] = useState(false);
  const [dates, setDates] = useState(null);
  const isMobile = useIsMobile();

  useEffect(() => {
    document.title = 'Avisos meteorológicos | PMGM';
  }, []);

  const download = (format) => {
    if (!initDownloadRequest) {
      setInitDownloadRequest(true);
      return null;
    }
    console.log('here but _>> ', format);
    if (format.value === 'csv') {
      let url = `${c.api.weatherhistoryAlert}?export=csv`;
      url +=
        '&from_date=' +
        moment(dates.startDate).startOf('day').format(formatT).toString();
      url +=
        '&to_date=' +
        moment(dates.endDate).endOf('day').format(formatT).toString();
      // console.log(url)
      window.open(url, '_blank');
    }
  };

  return (
    <div className='page-alert'>
      <div className='page-header'>
        <div className='alighL'>
          <div className='title-icon'>
            <svg
              width='19'
              height='16'
              viewBox='0 0 19 16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M17.8438 13.75C18.1146 14.25 18.1042 14.75 17.8125 15.25C17.5417 15.75 17.1146 16 16.5312 16H1.53125C0.947917 16 0.510417 15.75 0.21875 15.25C-0.0520833 14.75 -0.0520833 14.25 0.21875 13.75L7.71875 0.75C8.01042 0.25 8.44792 0 9.03125 0C9.61458 0 10.0521 0.25 10.3438 0.75L17.8438 13.75ZM10.0312 11.5C9.76042 11.2083 9.42708 11.0625 9.03125 11.0625C8.63542 11.0625 8.29167 11.2083 8 11.5C7.72917 11.7708 7.59375 12.1042 7.59375 12.5C7.59375 12.8958 7.72917 13.2396 8 13.5312C8.29167 13.8021 8.63542 13.9375 9.03125 13.9375C9.42708 13.9375 9.76042 13.8021 10.0312 13.5312C10.3229 13.2396 10.4688 12.8958 10.4688 12.5C10.4688 12.1042 10.3229 11.7708 10.0312 11.5ZM7.65625 5.90625L7.90625 10.1562C7.90625 10.3854 8.03125 10.5 8.28125 10.5H9.78125C10.0312 10.5 10.1562 10.3854 10.1562 10.1562L10.4062 5.90625C10.4062 5.78125 10.3646 5.6875 10.2812 5.625C10.2188 5.54167 10.1354 5.5 10.0312 5.5H8.03125C7.92708 5.5 7.83333 5.54167 7.75 5.625C7.6875 5.6875 7.65625 5.78125 7.65625 5.90625Z'
                fill='#000C7F'
              />
            </svg>
          </div>
          <TitleH3 title='Avisos meteorológicos' />
        </div>

        <div className='alighR'>
          <DropdownDatepicker
            labelInfo='Período selecionado'
            dropdownTitle='Intervalo padrão'
            onEvent={(e) => setDates(e)}
            dateStart={moment().locale('pt').add(-30, 'days')}
            dateEnd={moment().locale('pt')}
            dateBigBang={moment('01-01-2021').locale('pt')}
            isMobile={isMobile}
          />

          <Dropdown
            onEvent={download}
            labelInfo='Exportar'
            list={[{ value: 'csv', description: 'Formato CSV' }]}
          />
        </div>
      </div>

      <div className='box-wrapper'>
        <TitleBox title='Lista de avisos meteorológicos' />
        <DescriptionBox title='Avisos enviados pelo IPMA.' />
        <div className='table-wrapper'>
          {dates && <SmartTable dates={dates} />}
        </div>
      </div>
    </div>
  );
};

export default WarningScreen;
