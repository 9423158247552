import styled from 'styled-components';

export const CssWrapper = styled.div`
  padding-left: ${(props) => props.theme.size.spacing_6};
  padding-right: ${(props) => props.theme.size.spacing_6};
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(
    auto-fit,
    minmax(${(props) => props.theme.size.layout_10}, 1fr)
  );
  grid-auto-columns: ${(props) => props.theme.size.layout_10};
  grid-auto-flow: dense;
  @media screen and (max-width: 400px) {
    grid-template-columns: repeat(
      auto-fit,
      minmax(${(props) => props.theme.size.layout_9}, 1fr)
    );
    grid-auto-columns: ${(props) => props.theme.size.layout_9};
  }
`;

export const CssBody = styled.div`
  background-color: black;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 1920px;
  height: 1080px;
`;

export const CssWStationwrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  padding: 16px;
  width: 1920px;
  height: 470px;
`;

export const CssMainStation = styled.div`
  display: flex;
  width: 507px;
  height: 436px;

  & > div {
    width: 100%;
    margin-right: 8px;
    height: 100%;
  }
`;

export const CssSmallStation = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 1413px;
  height: 479px;
  & > div:nth-child(-n + 3) {
    margin-bottom: 8px;
  }
  & > div {
    margin-right: 8px;

    width: 400px;
    height: 190px;
  }
`;

export const CssWarningWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 987px;
  height: 462px;

  & > div {
    margin: 8px;
    width: 100%;
  }
`;

export const CssForcastWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 925px;
  height: 462px;
  & > div {
    margin: 8px;
    width: 100%;
  }
`;

export const CssIPMAwrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 1920px;
  height: 462px;
`;

export const CssWarningMsgWrapper = styled.div`
  width: 1900px;
  height: 100px;
  padding: 8px;
  & > div {
    margin-top: 0px;
  }
`;
