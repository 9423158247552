const localization_PT = {
        body: {
            emptyDataSourceMessage: 'Nenhum registro para exibir'
        },
        toolbar: {
            searchTooltip: 'Pesquisar'
        },
        pagination: {
            labelRowsSelect: 'linhas',
            labelDisplayedRows: `{from}-{to} de {count}`,
            firstTooltip: 'Primeira página',
            previousTooltip: 'Página anterior',
            nextTooltip: 'Próxima página',
            lastTooltip: 'Última página'
        }
    
}
export default localization_PT