export default {
    map: {
        zoom  : 13,
        center: [41.156608, -8.614529],
        tile  : 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png'
    },
    api:{
        weatherhistoryAlert : `${window.bootstrap.API_HISTORY_URL}/v1/entity-type/Alert`,
        weather_station_list: `${window.bootstrap.API_URL}/v1/WeatherObserved?type=WeatherObserved`,
        weather_station     : `${window.bootstrap.API_HISTORY_URL}/v1/entity-id/`,
        ipmaActiveAlerts    : `${window.bootstrap.API_CONTEXT}/v1/Alert?query=category==weather;alertSource==IPMA;severity!=low&orderBy=severity,validFrom`,
        ipmaWeatherForecast : `${window.bootstrap.API_CONTEXT}/v1/WeatherForecast`,
        ipmaAggregateForecast: window.bootstrap.IPMA_AGGREGATE_FORCAST,
        weatherObservedHistory : `${window.bootstrap.API_HISTORY_URL}/v1/entity-type/WeatherObserved`,
        cityLimits: `https://mipweb.cm-porto.pt/arcgis/rest/services/INFORMACAO_BASE/IG_ENQUADRAMENTO/MapServer/6/query?where=1%3D1&text=&objectIds=&time=&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&relationParam=&outFields=*&returnGeometry=true&returnTrueCurves=false&maxAllowableOffset=&geometryPrecision=&outSR=4326&returnIdsOnly=false&returnCountOnly=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&returnZ=false&returnM=false&gdbVersion=&returnDistinctValues=false&resultOffset=&resultRecordCount=&queryByDistance=&returnExtentsOnly=false&datumTransformation=&parameterValues=&rangeValues=&f=geojson`
    },
    webSocket: `${window.bootstrap.WEBSOCKET}`,
    timeInterval: {
        dashboard: 600000 // 10 min to request new data
    },
    timeStationWithUpToDateData:60, // in minutes
    app_version: `${window.bootstrap.APP_VERSION}`
}
