import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';
import { criteria } from '../../../components/ipmaHelper';
import { MediaSvg } from '@apd/media-svg';
import useModal from 'react-hooks-use-modal';
import { FullStationBox } from './FullStationBox';
import c from '../../../constants/config';

import {
  CssBox,
  CssTitle,
  CssContent,
  CssCol,
  CssArea,
  CssData,
  CssValue,
  CssTag,
  CssXS,
  CssOffMsg,
  CssBackground,
  CssIcon,
  CsSmallDateTime
} from './StationBox-style';

import moment from 'moment';
import 'moment/min/locales';
moment.locale('pt');

export const StationBox = (props) => {
  const [Modal, open, close] = useModal('root', {
    preventScroll: window.innerWidth > 720
  });

  return (
    <>
      <CssBox key={'btn' + props.id} onClick={open}>
        <StationBoxTemplate {...props} />
      </CssBox>

      <Modal>
        <FullStationBox {...props} onModalClose={close} hasCloseButton={props.hasCloseButton} />
      </Modal>
    </>
  );
};

/**
 *
 * TEMPLATE for the Offine message
 */
const OfflineMessage = ({ dateTime }) => (
  <CssOffMsg>
    <p>
      Sem dados atuais.
      <CsSmallDateTime>{dateTime}</CsSmallDateTime>
    </p>
    <CssBackground> </CssBackground>
  </CssOffMsg>
);

/**
 *
 * TEMPLATE for the Station Box - small info
 */
const StationBoxTemplate = ({
  name,
  temperature,
  windSpeed,
  precipitation,
  dateObserved
}) => {
  const themeContext = useContext(ThemeContext);
  const dateNow = moment.utc();
  const start = moment.utc(dateObserved);
  let isUpdated = true;
  if (dateNow.diff(start, 'minutes', true) > c.timeStationWithUpToDateData)
    isUpdated = false;

  return (
    <>
      {!isUpdated ? <OfflineMessage dateTime={dateObserved} /> : null}
      <CssTitle>{decodeURI(name)} </CssTitle>
      <CssContent>
        <CssCol>
          <CssArea>
            <CssIcon>
              <MediaSvg
                name={`ipma:temp`}
                fill={themeContext.colors.text.category}
              />
            </CssIcon>
            Temperatura
          </CssArea>
          <CssData>
            <CssValue wcriteria={criteria.temp(temperature)}>
              {Number(temperature) ? temperature.toFixed(1) : (temperature ?? '-')}
              <CssXS>ºC</CssXS>
            </CssValue>
            <CssTag>Atual</CssTag>
          </CssData>
        </CssCol>
        <CssCol>
          <CssArea>
            <CssIcon>
              <MediaSvg
                name={`ipma:wind`}
                fill={themeContext.colors.text.category}
              />
            </CssIcon>
            Vento
          </CssArea>
          <CssData>
            <CssValue
              wcriteria={criteria.wind(Math.round(windSpeed * 36) / 10)}
            >
              {Number(windSpeed) ? Math.round(windSpeed * 36) / 10 : (windSpeed ?? '-')}
              <CssXS>km/h</CssXS>
            </CssValue>
            <CssTag>Vel. atual</CssTag>
          </CssData>
        </CssCol>
        <CssCol>
          <CssArea>
            <CssIcon>
              <MediaSvg
                name={`ipma:rainfall`}
                fill={themeContext.colors.text.category}
              />
            </CssIcon>
            Precipitação
          </CssArea>
          <CssData>
            <CssValue>
              {precipitation == null ? '-': precipitation}
              <CssXS>mm</CssXS>
            </CssValue>
            <CssTag>Pp. atual</CssTag>
          </CssData>
        </CssCol>
      </CssContent>
    </>
  );
};

StationBoxTemplate.propTypes = {
  name: PropTypes.string,
  dateObserved: PropTypes.string,
  temperature: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  windSpeed: PropTypes.number,
  precipitation: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

StationBoxTemplate.defaultProps = {
  name: '- -',
  dateObserved: '2020-00-00T00:00:00.00Z',
  temperature: '-',
  windSpeed: '-',
  precipitation: '-'
};
