import React, { useState, useEffect } from 'react'
import Dropdown from '@apd/dropdown'
import axios from 'axios'
import c from '../../../../constants/config'
import { dropDownSchema } from '../../../../schema'


export const DropdownStation = ({ event }) => {
    const [stationList, setStationList] = useState([])
    const [selected, setSelected] = useState(null)

    useEffect(() => {
        axios.get(c.api.weather_station_list)
        .then(r => {
            let list = []
            dropDownSchema.validateSync(r.data)
            r.data.map(item => {
                list.push({ 
                    value: item.id, 
                    description: item.name })
                    return null
            })
            setStationList(list)
        })
        .catch(errors => console.log('Error', errors))
    }, [])

    const onClick = e => setSelected(e.value)

    useEffect(() => {
        if (selected === null) return
        event(selected)
    }, [event, selected])

    return (
        <Dropdown
            onEvent={onClick}
            labelInfo="Estação meteorológica"
            list={stationList} />
    )
}