import React, { useEffect, useMemo } from 'react';

import { TitleH3 } from '../../components/UI/UI';
import { NextDayBox } from './Components/NextDayBox';
import { StationBox } from './Components/StationBox';

import { IpmaWarning } from '../../components/IpmaWarningsBox/IpmaWarningCarouselBasic';

import { CssAlertBox, CssTitle } from './Components/StationBox-style';
import { CssWrapper } from './DashboardScreen-style';
import { IpmaTimeline } from '../../components/IpmaTimeline/IpmaTimeline';
import { useImpaActiveAlerts } from '../../hooks/useAtiveIpmaAlerts';
import { useSocketWeatherObserved } from '../../hooks/useSocketWeatherObserved';
import { useIpmaForecast } from '../../hooks/useIpmaForecast';

// var _paq = (window._paq = window._paq || []);
// /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
// _paq.push(['trackPageView']);
// _paq.push(['enableLinkTracking']);
// (function () {
//   var u = 'https://matomo.porto.digital/';
//   _paq.push(['setTrackerUrl', u + 'matomo.php']);
//   _paq.push(['setSiteId', '15']);
//   var d = document,
//     g = d.createElement('script'),
//     s = d.getElementsByTagName('script')[0];
//   g.async = true;
//   g.src = u + 'matomo.js';
//   s.parentNode.insertBefore(g, s);
// })();

const DashboardScreen = () => {
  const { wStationList } = useSocketWeatherObserved();
  const { alertList, isLoading: isAlertsLoading } = useImpaActiveAlerts();

  const { next2Day, nextDay } = useIpmaForecast();

  useEffect(() => {
    document.title = 'Dashboard | PMGM';
  }, []);

  const NextDayMemo = useMemo(() => <NextDayBox {...nextDay} />, [nextDay]);
  const Next2DayMemo = useMemo(() => <NextDayBox {...next2Day} />, [next2Day]);

  const StationBoxMemo = useMemo(
    () =>
      Object.keys(wStationList).map((id) => (
        <StationBox key={id} {...wStationList[id]} hasCloseButton />
      )),
    [wStationList]
  );

  return (
    <div className='analytics-page'>
      <div className='page-header'>
        <div className='alighL'>
          <TitleH3 title='Dashboard' />
        </div>
      </div>
      {/* new layout using theme logic */}
      {!isAlertsLoading ? (
        <CssWrapper>
          <CssAlertBox>
            <CssTitle>Avisos ({Object.keys(alertList).length})</CssTitle>
            <IpmaTimeline alerts={alertList} />
            <IpmaWarning alerts={alertList} />
          </CssAlertBox>

          {NextDayMemo}
          {Next2DayMemo}

          {StationBoxMemo}
        </CssWrapper>
      ) : null}
    </div>
  );
};

export default DashboardScreen;
