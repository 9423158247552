import React, { useState, useEffect } from 'react'
import { Line } from 'react-chartjs-2'
import c from '../../../../constants/config'
import axios from 'axios'
import moment from 'moment'
import 'moment/min/locales'
import { uVIndexMaxSchema } from '../../../../schema'
moment.locale('pt')

export const ChartUV = ({ from_date, to_date, aggr_period, station_id }) => {
    const [dataSetMax, setDataSetMax] = useState([])
    const [dataLabels, setDataLabels] = useState([])
    const [dataHighNumber, setDataHighNumber] = useState(0)

    useEffect(() => {
        if (!station_id || !from_date || !to_date || !aggr_period) return
        setDataSetMax([])
        setDataLabels([])
        
        const getmax = axios.get(`${c.api.weather_station}${station_id}?from_date=${from_date}&to_date=${to_date}&attribute_name=uVIndexMax&aggr_period=${aggr_period}&aggr_method=max`)
 
        axios
            .all([getmax])
            .then(
                axios.spread((...responses) => {
                    let highNumber = 0
                    let label = []
                    let max = []
                    uVIndexMaxSchema.validateSync(responses[0].data)
                    responses[0].data.request_entities.map((item , index) => {
                        label.push(item.date)
                        max.push(item.uVIndexMax)
                       
                        if (highNumber<item.temperature) highNumber = item.uVIndexMax
                
                        return null
                    })

                    setDataSetMax(max)
                    setDataLabels(label)
      
                    setDataHighNumber(Math.ceil(Math.round(highNumber+5) / 5) * 5)                    

                })).catch(errors => console.log('Error', errors))

	}, [from_date, to_date, aggr_period, station_id])

    return (
        <Line
            data={{
                labels: dataLabels,
                datasets: [
                    {
                        label: 'Máxima',
                        data: dataSetMax,
                        fill: false,
                        backgroundColor: '#4D57BC',
                        borderColor: '#4D57BC',
                    }
                ]
            }}
            // width={100}
            // height={330}
            options={{
                responsive: true,
                maintainAspectRatio: false,
                tooltips:{
                    mode: 'index',
                    intersect: false
                },
                hover: {
                    mode:'nearest',
                    intersect: true
                },
                legend: {
                    align: "end"
                },
                scales: {
                    xAxes: [{
                        offset: true,
                        type: 'time',
                        time: {
                            stepSize: 1,
                            unit: aggr_period,
                            tooltipFormat: 'D MMM YYYY - h a'
                        },
                        scaleLabel: {
                            display: true,
                            labelString: `${ moment.tz(moment(from_date, "YYYY-MM-DD[T]H:mm:ss.000"), 'Europe/Lisbon').format('DD MMM YYYY - HH:mm') } | ${moment.tz(to_date, 'Europe/Lisbon').format('DD MMM YYYY - HH:mm') }`,
                            fontSize: 14,
                            fontStyle: "normal",
                            padding: 24,
                        }
                    }],
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            max: dataHighNumber,
                        }
                    }]
                }
            }}
        />
    )
}