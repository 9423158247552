import React, {useContext} from 'react'
import PropTypes from 'prop-types'
import { CssAlertTemplate, CssIconWrapper, CssMsgWrapper, CssDescription, CssDate } from './MessageWarning-style'

import { ThemeContext } from 'styled-components';

import {MediaSvg} from  '@apd/media-svg' 

import moment from 'moment'
import 'moment/min/locales'
moment.locale('pt')

const setup = {
    dateFormat: 'YYYY-MM-DD - H:mm'
}

export const MessageWarning = ({description, severity, validFrom, validTo, subCategory}) => {
    const themeContext = useContext(ThemeContext);
    return (
    <CssAlertTemplate severity={severity}>
        <CssIconWrapper >
            <MediaSvg name={`ipma:${subCategory}`} fill={themeContext.colors.svg.default}/>

        </CssIconWrapper>
        <CssMsgWrapper>
            <CssDescription>
                {description || 'n/a'}
            </CssDescription>
            <CssDate>
                {moment(validFrom).format(setup.dateFormat)} até {moment(validTo).format(setup.dateFormat)}
            </CssDate>
        </CssMsgWrapper>
    </CssAlertTemplate>
)}


MessageWarning.propTypes = {
    description: PropTypes.string,
    severity   : PropTypes.string,
    validFrom  : PropTypes.string,
    validTo    : PropTypes.string,
    subCategory: PropTypes.string,
}

MessageWarning.defaultProps = {
    description: " ",
    severity   : " ",
    validFrom  : " ",
    validTo    : " ",
    subCategory: " ",
}