import React from 'react'
import { Doughnut } from 'react-chartjs-2'
import '../../css/globalStyle.css'
import './UI.css'
import c from '../../constants/config'
import Skeleton from 'react-loading-skeleton';


/* 
    This is a UI title
*/
export const TitleH3 = props => (
    <h3 className="h3APD"> {props.icon}{props.title} </h3>
);

/* 
    This is a UI title
*/
export const TitleBox = props => (
    <h3 className="tBoxAPD"> { props.title} </h3>
);
/* 
    This is a UI title
*/
export const DescriptionBox = (props) => (
    <p className="descBoxAPD"> { props.title} </p>
);


/* 
    This is a UI 
*/
export const Checkbox = props => (
    <div className="checkbox_line">
        <input type="checkbox" id={props.text} name={props.text} {...props} />
        <label > {props.text} </label>
    </div>
)

/* 
    This is a UI title small
*/
export const TitleP = props => (
    <p className="title-p"> { props.title} </p>
);

/* 
    This is a chart
*/
export const MyDoughnut = props => {
  
    let _use = (props.showOwner.circ ? props.info.circ.use.length : 0) + (props.showOwner.bird ? props.info.bird.use.length : 0) 
    let _parked = (props.showOwner.circ ? props.info.circ.parked.length : 0) + (props.showOwner.bird ? props.info.bird.parked.length : 0)  //props.info.circ.parked.length || 0
    let _outOffService = (props.showOwner.circ ? props.info.circ.outOffService.length : 0) + (props.showOwner.bird ? props.info.bird.outOffService.length : 0) //props.info.circ.outOffService.length || 0
    let _alert = (props.showOwner.circ ? props.info.circ.alert.length : 0) + (props.showOwner.bird ? props.info.bird.alert.length : 0) // props.info.circ.alert.length || 0
    let total = (_use + _parked + _outOffService + _alert) || 0

    const data = {
        labels: [
            'Em utilização',
            'Estacionada',
            'Fora de serviço',
            'Alerta'
        ],
        datasets: [{
            data: [
                _use,
                _parked,
                _outOffService,
                _alert
            ],
            backgroundColor: [
                c.color.inUse,
                c.color.parked,
                c.color.outOffService,
                c.color.alert
            ],
            hoverBackgroundColor: [
                c.color.inUse,
                c.color.parked,
                c.color.outOffService,
                c.color.alert
            ]
        }]
    };

    const options = {
        legend: {
            display: false,
        },
        cutoutPercentage: 80,
        maintainAspectRatio: false
    };



    return (
        <div className="DoughnutWrapper">
            <TitleP title="Estado de veículos" />

            <div className="chart">
                <div className="centerContent">
                    <p className="body_1_Highlight">{total}</p>
                    <p className="body_1">Veículos</p>
                </div>
                <Doughnut
                    data={data}
                    options={options} />
            </div>
            <div className="legend">
                <LegendLine
                    tag="Em utilização"
                    counter={_use}
                    color={c.color.inUse} />
                <LegendLine
                    tag="Estacionada"
                    counter={_parked}
                    color={c.color.parked} />
                <LegendLine
                    tag="Fora de serviço"
                    counter={_outOffService}
                    color={c.color.outOffService} />
                <LegendLine
                    tag="Alerta"
                    counter={_alert}
                    color={c.color.alert} />
            </div>
            <div className=""></div>
        </div>
    )
};

/* 
    This is a UI 
*/
export const LegendLine = props => (
    <div className="legendLine">
        <div className="miniCircle" style={{ background: props.color }} />
        <div className="legendTag body_2"> {props.tag}</div>
        <div className="counter body_1_Highlight">{props.counter}</div>
    </div>
);



/* 
    This is a UI 
*/

export const InfoTagValue = ({ tag, value, color }) => (
    <div className="InfoTagValue">
        <div className="tag">{tag}</div>
        <div className="value" style={{ color: color }}>{value}</div>
    </div>
)



/* 
    This is a UI KPI 
*/

export const ShowKpiInfo = ({ tag, value, loading }) => {
    const _Load = loading === undefined ? false : loading 
    return (
        <div className="ShowKpiInfo">
            <div className="tag">{_Load ? <Skeleton duration={1.2} width={50} color="#202020" highlightColor="#ffffff" /> : tag }</div>
            <div className="value">{_Load ? <Skeleton duration={1.2} width={100} color="#202020" highlightColor="#ffffff" /> : value}</div>
        </div>
    )
}
