import styled from 'styled-components'

export const CssAlertTemplate = styled.div`
    background-color: ${props => props.theme.colors.bg.ipma[props.severity]};
    border-radius   : ${props => props.theme.radius.medium};
    flex            : 1;
    position        : relative;
    padding         : ${props => props.theme.size.spacing_4};
    display: flex;
    flex-direction: row;
`

export const CssIconWrapper = styled.div`
    height: 100%;
    width: ${props => props.theme.size.layout_5};
`

export const CssMsgWrapper = styled.div`
margin-left: ${props => props.theme.size.spacing_4};
height: 100%;
display: flex;
flex            : 1;
flex-direction: column;
justify-content: center;
`

export const CssDescription = styled.p`
    font-style: normal;
    font-weight: 400;
    margin    : 0;
    font-size: 16px;
    text-align: left;
    color     : ${props => props.theme.colors.text.ipma[`${props.severity}_1`]};
`

export const CssDate = styled.p`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    margin    : 0;
    text-align: left;
    color     : ${props => props.theme.colors.text.ipma[`${props.severity}_1`]};
`