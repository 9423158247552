// import { useState, useEffect } from 'react';
import c from '../constants/config';
import { useGetAPI } from './useGetAPI';

export const useImpaActiveAlerts = () => {
  //   const [alertList, setAlertList] = useState([]);
  //   const [isReq, setIsReq] = useState(true);
  const { data, error, isLoading } = useGetAPI(c.api.ipmaActiveAlerts, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshInterval: c.timeInterval.dashboard
  });

  if (error) {
    console.error(error);
  }

  // useEffect(() => {
  //     if (!isReq) return null

  //     axios.get(c.api.ipmaActiveAlerts)
  //         .then(r => {

  //             setAlertList(r.data)
  //             setIsReq(false)
  //         })
  //         .catch(err => {
  //             setIsReq(false)
  //             console.log("ERROR from IPMA API: ", err)
  //         })
  // }, [isReq])

  return { alertList: data, isLoading };
};
