import { array, number, object, string } from "yup";

export const temperatureSchema = object().shape({
  attribute_name: string().equals(["temperature"]).required(),
  entity_id: string().required(),
  request_entities: array().of(
    object().shape({
      temperature: number().required(),
      date: string().required(),
    })
  ),
});

export const windSpeedSchema = object().shape({
  attribute_name: string().equals(["windSpeed"]).required(),
  entity_id: string().required(),
  request_entities: array().of(
    object().shape({
      windSpeed: number().required(),
      date: string().required(),
    })
  ),
});

export const precipitationSchema = object().shape({
  attribute_name: string().equals(["precipitation"]).required(),
  entity_id: string().required(),
  request_entities: array().of(
    object().shape({
      precipitation: number().required(),
      date: string().required(),
    })
  ),
});

export const uVIndexMaxSchema = object().shape({
  attribute_name: string().equals(["uVIndexMax"]).required(),
  entity_id: string().required(),
  request_entities: array().of(
    object().shape({
      uVIndexMax: number().nullable(),
      date: string().required(),
    })
  ),
});

export const dropDownSchema = array().of(
  object().shape({
    id: string().required(),
    name: string().required(),
  })
);
