import React, {useContext}from 'react'
import { ThemeContext } from 'styled-components'

import {
    CssRow,
    CssType,
    CssCellWarning,
    CssRowSize,
} from './IpmaTimeline-style'

import {MediaSvg} from  '@apd/media-svg' 

const RowsWarning = ({ type, alerts, hoursArray }) => {
    const themeContext = useContext(ThemeContext)
    return (
    
    <CssRowSize>
        <CssType>
            <MediaSvg name={`ipma:${type}`} fill={themeContext.colors.svg.default} />
        </CssType>
        <CssRow className="border">
            {Object.keys(alerts).map((k) =>
                <CssCellWarning
                    severity={alerts[k]}
                    hour={hoursArray[k]}
                    key={type + k} />
            )}
        </CssRow>
    </CssRowSize>
)}

export default RowsWarning
