import styled from 'styled-components'

export const CssTable = styled.div`
    margin-top: ${props => props.theme.size.spacing_6};
`

export const CssBodyWrapper = styled.div`
    &>div:not(:last-child){
        margin-bottom: ${props => props.theme.size.spacing_1};
    }
`

export const CssType = styled.div`
    width: 20px;
    height: 20px;
    float: left;
`

export const CssRow = styled.div`
    display: flex;
    
`

const CssCellTemplate = styled.div`
    height: 20px;
    width: fill-available;
`

export const CssCellWarning = styled(CssCellTemplate)`
    background-color: ${props => props.theme.colors.bg.ipma[props.severity]};

    box-shadow: 
        inset   ${props => props.hour === "00" ? "5px" : "4px" } 
                0px 0px -3px 
            ${props => props.hour === "00" ? 
                props.theme.colors.border.ipma.cell.active : 
                props.theme.colors.border.ipma.cell.default},  // left
        inset 0 -4px 0px -3px ${props => props.theme.colors.border.ipma.cell.default}, // top
        inset -4px 0 0px -3px ${props => props.theme.colors.border.ipma.cell.default}, // right
        inset 0px 4px 0px -3px ${props => props.theme.colors.border.ipma.cell.default}; // right

`

/** ---------------------------------
 * HEADER ROW
 * --------------------------------- */ 
export const CssCellHeader = styled(CssCellTemplate)`
margin-bottom: ${props => props.theme.size.spacing_1};
`

export const CssCellHeaderHour = styled(CssCellTemplate)`
    box-shadow: 
        inset 5px 0px 0px -3px ${props => props.hour === "00" ? 
            props.theme.colors.border.ipma.cell.active: 
            props.theme.colors.border.ipma.cell.default};
    z-index : 10;
    position: relative;
    margin-bottom: ${props => props.theme.size.spacing_1};
    &:after {
        content     : ${props => JSON.stringify(props.hour)};
        padding-left: ${props => props.theme.size.spacing_2};
        color       : ${props => props.theme.colors.text.default_2};
        font-size   : 12px;
        position    : absolute;
        left        : 0;
        z-index     : 11;
    }
`
export const CssCellHeaderMarker = styled(CssCellTemplate)`
    margin-top: 10px;
    height    : 10px;
    box-shadow: 
        inset 4px 0px 0px -3px ${props => props.theme.colors.border.ipma.cell.default};
        margin-bottom: ${props => props.theme.size.spacing_1};

`

/** ---------------------------------
 * FOOTER ROW
 * --------------------------------- */ 
export const CssCellFooter = styled(CssCellTemplate)`
    margin-top: 2px;
    box-shadow: 
        inset ${props => props.hour === "00" ? 
            "5px" : "0px"} 0px 0px -3px
            ${props => props.theme.colors.border.ipma.cell.active};
    z-index : 10;
    position: relative;
    &:after {
        content     : ${props => JSON.stringify(props.day)};
        padding-top: ${props => props.theme.size.spacing_2};
        padding-left: ${props => props.theme.size.spacing_2};
        color       : ${props => props.theme.colors.text.default_2};
        font-size   : 12px;
        position    : absolute;
        left        : 0;
        z-index     : 11;
        width       : 40px;
    }
`

export const CssRowSize = styled.div`

`