import styled from 'styled-components'

export const CssAlertWrapper = styled.div`
    height        : 100%;
    display       : flexbox;
    flex-direction: row;
    width         : 100%;
    overflow      : hidden;
    position      : absolute;
`

export const CssBox = styled.div`
    width        : ${props => props.theme.size.layout_11};
    display      : flex;
    position     : relative;
    padding-right: ${props => props.theme.size.spacing_4};
`
