import React, { useState, useEffect, useRef } from 'react'
import { CssAlertWrapper } from './IpmaWarningCarouselBasic-style'
import { MessageNoWarning } from './MessageNoWarning/MessageNoWarning'
import { MessageWarning } from './MessageWarning/MessageWarning'

import moment from 'moment'
import 'moment/min/locales'
moment.locale('pt')

/**
 * setup this component
 */

const setup = {
    timer2Show: 15000
}

/**
 * 
 * AlertMessage
 *  
 */

export const IpmaWarning = ({ alerts }) => {
    const [list, setList] = useState([])
    const [main, setMain] = useState(0)
    const increment = useRef(null);

    useEffect(() => {
        setList(alerts)
    }, [alerts])


    useEffect(() => {
        let count = main
        increment.current = setInterval(() => {

            if (list.length <= 1) return

            if (list.length - 1 === count) {
                count = 0
                setMain(count)
                return
            }
            count = count + 1
            setMain(count)

        }, setup.timer2Show);

        return () => clearInterval(increment.current)

    }, [list, main]);


    if (list.length < 1) {
        return (
            <CssAlertWrapper>
                <MessageNoWarning />
            </CssAlertWrapper>)
    }

    return (
        <CssAlertWrapper>
            <MessageWarning {...list[main]} />
        </CssAlertWrapper>
    )
}
