import { object, array, string, number } from 'yup';

export const weatherObservedSchema = object().shape({
  id: string().required(),
  temperature: number().nullable(),
  location: object().shape({
    coordinates: array().of(number()).length(2).nullable()
  }),
  dateObserved: string().nullable(),
  name: string().required(),
  windSpeed: number().nullable(),
  precipitation: number().nullable(),
  atmosphericPressure: number().nullable(),
  uVIndexMax: number().nullable()
});
